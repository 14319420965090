import React, { useState } from 'react';
import { loggedInClient } from '../services/rest';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';

// This function works for the data type .img and
// is intended for web, because document scanning does not make
// sense for web. This also works for multiple images.
function DocumentDropzone() {
  const { subjectId } = useSelector((state) => state.User);

  const [uploadStatus, setUploadStatus] = useState('');
  const [filePreviews, setFilePreviews] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      setUploadStatus('Upload läuft...');
      const previews = acceptedFiles.map(file => ({
        url: URL.createObjectURL(file),
        name: file.name
      }));
      setFilePreviews(previews);
      try {
        for (const file of acceptedFiles) {
          const response = await loggedInClient.importRecord(
            subjectId,
            'labordocs',
            '<labordocs_comment> </labordocs_comment>',
            file,
            'labordocs_file',
          );
          console.log(response);
        }
        setUploadStatus('Upload erfolgreich!');
      } catch (error) {
        console.log('None or not all files could be uploaded: ' + error);
        setUploadStatus(
          'Upload fehlgeschlagen! Bitte versuchen Sie es noch einmal. Bei wiederholtem Fehlschlagen, versuchen Sie bitte die manuelle Dateneingabe oder kontaktieren Sie den Support.',
        );
        // throw error;
      }
    },
  });

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Ziehen Sie Ihre Datei per Drag and Drop hier her, oder klicken Sie auf diesen Text, um eine Datei auszuwählen.</p>
        <p>{uploadStatus}</p>
        <div>
          {filePreviews.map((file, index) => (
            <div key={index}>
              <img src={file.url} alt={file.name} style={{ width: '100px'}} />
              <p>{file.name}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DocumentDropzone;
