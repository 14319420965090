/* eslint-disable lines-around-directive */
// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React, {useEffect, useState} from 'react';
import {Dimensions, Image, StyleSheet, Text, TouchableOpacity, View,} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {navigationPropType} from '../propTypes';

import {useIsFocused} from '@react-navigation/native';
// import { BarcodeFormat, scanBarcodes } from 'vision-camera-code-scanner';
import NetInfo from '@react-native-community/netinfo';
import { runOnJS } from 'react-native-reanimated';

// custom components
import {Banner} from '../components/shared';
import {Routes, Stacks} from '../navigation/constants';
// import InternetConnectionFail from '../components/InternetConnectionFail';
import QRCodeScanner from '../components/qrcode/QRCodeScanner';

// redux actions
import { sendCredentials } from '../store/user.slice';

// services & config
import { appConfig, theme } from '../config';
import translate from '../services/localization';
import endpoints from '../services/rest/endpoints';
import { Button, Icon, Input } from '@rneui/themed';
import { Divider } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';

/**
 * tries to parse the input-string and returns the subjectId (from the qr-code)
 * @param  {string} str string to be checked
 * @returns {string}
 */
const checkQrCodeForUsername = (str) => {
  let subjectId;
  try {
    const qrCode = JSON.parse(str);
    // for web: keep the '.name', otherwise the subjectId won't be read from the string
    if (
      qrCode[appConfig.qrCodeAttributeHoldingTheAppIdentifier] ===
        appConfig.appIdentifier.name ||
      qrCode[appConfig.qrCodeAttributeHoldingTheAppIdentifier] ===
        appConfig.alternativeAppIdentifier.name
    ) {
      subjectId = qrCode[appConfig.qrCodeAttributeHoldingTheSubjectId];
    }
  } catch (e) {
    return '';
  }
  // returns the id or an empty string if no id could be found
  return subjectId || '';
};

/***********************************************************************************************
 * component:
 * renders the login-screen with the qr code scanner
 *
 * @param  {object}    props
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function LoginScreen({ navigation }) {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const [isReachable, setIsReachable] = useState(true);
  const [manualSubjectID, setManualSubjectID] = useState('');

  const [videoStream, setVideoStream] = useState(null);

  // NetInfo.configure({
  //     reachabilityUrl: endpoints.ping,
  //     reachabilityTest: async (response) => response.status === 204,
  //     reachabilityLongTimeout: 60 * 1000, // 60s
  //     reachabilityShortTimeout: 5 * 1000, // 5s
  //     reachabilityRequestTimeout: 3 * 1000, // 15s
  //     reachabilityShouldRun: () => true,
  //     shouldFetchWiFiSSID: true, // met iOS requirements to get SSID. Will leak memory if set to true without meeting requirements.
  //     useNativeReachability: false,
  // });

  // NetInfo.fetch().then((state) => {
  //     console.log('Connection type', state.type);
  //     console.log('Is connected?', state.isConnected);

  // TODO: reset the setIsReachable to state.isInternetReachable, just had to use true now
  // because base url where ping is tested isn't available right now (https://api.umfragetool.digital-medicine.org/api/')
  // setIsReachable(state.isInternetReachable);
  //     setIsReachable(true);
  //     console.log('Is reachable?', state.isInternetReachable);
  // });

  const { subjectId } = useSelector((state) => state.User);
  const { error } = useSelector((state) => state.Globals);
  const [hasPermission, setHasPermission] = useState(false);
  const [qrCodes, setQrCodes] = useState([]);

  // trigger login when barcode was detected
  useEffect(() => {
    if (qrCodes.length) {
      console.log('QR Code: ', qrCodes[qrCodes.length - 1]);
      // parses the input string to determine the subjectId (from the qr-code)
      const scannedSubjectId = checkQrCodeForUsername(qrCodes[0].toString());
      console.log('Scanned Subject ID: ', scannedSubjectId);

      // triggers the login if subjectId could be found
      if (scannedSubjectId) {
        dispatch(sendCredentials(scannedSubjectId));
      } else {
        console.log('No subject ID found in QR code');
      }
    }
  }, [qrCodes, dispatch]);

  useEffect(() => {
    if (subjectId) {
      // stops the video stream once the user has logged in successfully
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
      }
      return navigation.replace(Stacks.SIGNED_IN, { screen: Routes.CHECK_IN });
    }
    // triggers the auto-login when on the login-screen (only on DEV)
    // if (appConfig.automateQrLogin) {
    //   // parses the input string to determine the subjectId (from the qr-code)
    //   // TODO: uncomment the scannedId section
    //     const scannedId = checkQrCodeForUsername(
    //         appConfig.automateQrLoginSubjectId || '',
    //     );
    //   scannedId = '';
    //   // triggers the login
    //   dispatch(sendCredentials(scannedId));
    // }
  }, [dispatch, subjectId, navigation]);

  // rendering
  /*-----------------------------------------------------------------------------------*/
  return (
    <View testID="LoginScreen" style={localStyle.wrapper}>
      {/* banner */}
      <Banner
        nav={navigation}
        title={translate('login').title}
        subTitle={translate('login').subTitle}
        noMenu
      />
      <View
        style={{
          backgroundColor: theme.values.defaultBackgroundColor,
          paddingTop: 10,
          borderColor: theme.values.defaultSeparatorColor,
          width: 500,
          alignItems: 'center',
        }}
      >
        <Text style={[localStyle.infoText, { marginTop: 40 }]}>
          Wir empfehlen Ihnen die Nutzung der Alpha-1-KIDS App. Diese bietet
          Ihnen eine optimale Darstellung und Bedienung des Registers.
        </Text>

        <View style={{ flex: 1, alignItems: 'center', marginTop: 20 }}>
          <Text style={{ width: '100%', align: 'center', textAlign: 'center' }}>
            Laden Sie sich jetzt die Alpha-1-KIDS auf Ihr Smartphone herunter:
          </Text>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Image
              source={require('src/assets/images/qr-mobile.png')}
              style={{ height: 200, width: 200 }}
              resizeMode={'contain'}
            />
            <View style={{ flex: 1, flexDirection: 'column' }}>
              <TouchableOpacity
                onPress={() => {
                  window.open(
                    'https://play.google.com/store/apps/details?id=org.digital_medicine.aatm.app.android',
                  );
                }}
              >
                <Image
                  source={require('src/assets/images/googleplay.png')}
                  style={{ height: 250 / 4, width: 646 / 4 }}
                  resizeMode={'contain'}
                />
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  window.open(
                    'https://apps.apple.com/de/app/alpha-1-kids/id6476488061',
                  );
                }}
              >
                <Image
                  source={require('src/assets/images/appstore.png')}
                  style={{ height: 250 / 4.5, width: 748 / 4.5 }}
                  resizeMode={'contain'}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>

        <Text style={[localStyle.infoText, { marginTop: 40 }]}>
          Falls Sie statt der App das Web-Portal nutzen möchten, können Sie sich
          hier anmelden.
        </Text>
      </View>
      {isReachable && (
        <View
          style={{
            backgroundColor: theme.values.defaultBackgroundColor,
            paddingTop: 10,
            borderColor: theme.values.defaultSeparatorColor,
            width: 500,
            alignItems: 'center',
          }}
        >
          <Text style={localStyle.infoText}>
            Bitte geben Sie Ihren Zugangscode in das Eingabefeld ein. Betätigen
            Sie anschließend auf den "Anmelden"-Button.
          </Text>
          <View style={{ marginTop: 20, width: '85%' }}>
            <Input
              placeholder={'Eingabe Zugangscode'}
              onChangeText={(value) => setManualSubjectID(value)}
            />
          </View>
          <View style={{ alignItems: 'flex-start' }}>
            <Button
              type="clear"
              disabled={!manualSubjectID}
              onPress={() => {
                if (manualSubjectID != '') {
                  dispatch(sendCredentials(manualSubjectID));
                }
              }}
              title={'Anmelden'}
              testID="redirectBtn"
              icon={
                <Icon
                  name="check"
                  reverse
                  type="material-community"
                  color={theme.colors.primary}
                />
              }
            >
              Anmelden
            </Button>
          </View>
          <Divider style={localStyle.divider} />
          <Text style={localStyle.infoText}>
            Falls Ihr PC eine Webcam besitzt, können Sie auch den
            Zugangs-QR-Code einscannen:{' '}
          </Text>
          <SafeAreaView style={localStyle.streamView}>
            <QRCodeScanner
              setQRCodes={setQrCodes}
              setVideoStream={setVideoStream}
            />
          </SafeAreaView>
        </View>
      )}
    </View>
  );
}

LoginScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

// get width of screen to calculate camera view
const {width} = Dimensions.get('window');

const localStyle = StyleSheet.create({
    wrapper: {
        backgroundColor: theme.values.defaultBackgroundColor,
        // flex: 1,
        // position: 'static',
        // bottom: 0,
        height: '100%',
        // flexDirection: 'column',
        alignItems: 'left',
    },

    content: {
        alignItems: 'left',
    },

    camera: {
        marginTop: appConfig.scaleUiFkt(20),
        // maxHeight: 0.95 * width,
        // width: '95%',
        aspectRatio: 1.0,
        justifyContent: 'center',
    },

    infoText: {
        marginStart: 10,
        marginEnd: 10,
        textAlign: 'left',
        color: theme.colors.accent4,
        ...theme.fonts.subHeader1,
    },

    button: {
        ...theme.classes.buttonAlert,
        bottom: 0,
        padding: appConfig.scaleUiFkt(15),
        width: '80%',
    },

    buttonLabel: {
        ...theme.classes.buttonLabel,
    },

    qrImage: {
        width: 100,
        height: 100,
    },

    access_button: {
        flexDirection: 'row',
        justifyContent: 'center',
        paddingHorizontal: 20,
        backgroundColor: theme.colors.primary_light,
        borderColor: theme.colors.secondary,
        borderWidth: 3,
        borderRadius: 10,
        padding: 10,
        marginBottom: 30,
        alignItems: 'center',
        alignSelf: 'flex-start',
        margin: 10,
    },

    streamView: {
        marginTop: 30,
        width: 400,
        height: 400,
    },

    stream: {
        flex: 1,
    },

    divider: {
        marginTop: 20,
        marginBottom: 60,
    },
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

LoginScreen.screenName = 'Login';

export default LoginScreen;
