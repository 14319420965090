import React, { useState, useEffect, useRef } from 'react';
import { qrCodeScanningLogic } from './qrCodeScanningLogic';

const QRCodeScanner = ({ setQRCodes, setVideoStream }) => {
  useEffect(() => {
    qrCodeScanningLogic(setQRCodes, setVideoStream);
  }, []);

  return (
    <>
      <div id="loadingMessage">
        🎥 Kein Zugriff auf Webcam-Stream möglich (bitte stellen Sie sicher, dass
        Sie eine aktivierte Webcam haben).
      </div>
      <canvas id="canvas" hidden></canvas>
      <div id="output" style={{display: 'none'}}>
        <div id="outputMessage">Kein QR Code erkannt.</div>
        <div hidden>
          <b>Data:</b> <span id="outputData"></span>
        </div>
      </div>
    </>
  );
};

export default QRCodeScanner;
