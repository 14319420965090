// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import React, { useEffect } from 'react';
import {
  StyleSheet,
  View,
  StatusBar,
  LogBox,
  Platform,
  Text,
  ScrollView,
  Dimensions,
} from 'react-native';
import * as RNLocalize from 'react-native-localize';

import { Routes, Stacks } from './navigation/constants';

// components
// TODO: check if it is necessary to uncomment this. In gitea repo it is uncommented.
// import SplashScreen from 'react-native-splash-screen';

// redux & store
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// This import creates Uncaught ReferenceError: __DEV__ is not defined at ./src/config/appConfig.js -> should work now
import { persistor, reduxStore } from './store';

// navigation setup
import AppNavigator from './navigation/appNavigator';

// config
// the following imports create Uncaught Error: RNEncryptedStorage is undefined at ./src/services/localStorage.js -> should work now
import kioskMode from './config/kioskApiConfig';
import { theme } from './config';

// redux actions
// the following imports create Uncaught ReferenceError: __DEV__ is not defined at ./src/config/appConfig.js -> should work now
import { updateLanguage } from './store/user.slice';
import { init } from './store/globals.slice';

// custom components
// This import creates module not found errors in node_modules and build error for web. -> should work now
import { Spinner } from './components/shared';

// services
// find alternative for 'react-native-encrypted-storage' -> done, use AsyncStorage for web
import localStorage from './services/localStorage';
import { availableLanguages, initLocalization } from './services/localization';

// This import creates module not found errors in node_modules and build error for web.
// solved it by mocking FlashMessage for web
import FlashMessage from './components/shared/FlashMessage';
import PersistentColumnNavigation from './navigation/PersistentColumnNavigation';

import { useNavigationContainerRef, NavigationContainer } from '@react-navigation/native';

import {SafeAreaView} from "react-native-safe-area-context";
import {ActionSheetProvider} from "@expo/react-native-action-sheet";

/***********************************************************************************************
global variables / settings
***********************************************************************************************/

// needed by node-forge for the encryption functionality
global.Buffer = require('buffer').Buffer;

// deactivates the logbox-warning about the debugger running in the background
LogBox.ignoreLogs(['Remote debugger']);

// const NewAppNaviator = createBrowserApp(AppNavigator);

/***********************************************************************************************
Component
***********************************************************************************************/

function App() {
  /**
   * handler for when the user changes the system language
   */
  const handleLocalizationChange = () => {
    reduxStore.dispatch(
      updateLanguage(
        RNLocalize.findBestAvailableLanguage(Object.keys(availableLanguages)),
      ),
    );
  };

  useEffect(() => {
    RNLocalize.addEventListener('change', handleLocalizationChange);

    // load user language from localStorage and initialize localization
    localStorage.loadUserLanguage().then((langCode) => {
      initLocalization(langCode);
      reduxStore.dispatch(init());
      // in web getting TypeError: Cannot read properties of undefined (reading 'hide')
      // BUG: preliminary solution -> only use this in native version
      // Platform.OS === 'native' && SplashScreen.hide();
    });

    return () => {
      // removeEventListener when unmounting
      RNLocalize.removeEventListener('change', handleLocalizationChange);
    };
  }, []);

  // should this be a demo init kiosk mode
  if (kioskMode.active) kioskMode.initKioskMode();

  const navigationRef = useNavigationContainerRef();

  // return the basic view that contains the navigator
  return (
    // TODO: check if ActionSheetProvider is supported for web
    // <ActionSheetProvider>
    <View style={localStyle.container}>
      {Platform.OS === 'ios' && (
        <StatusBar barStyle={theme.values.defaultStatusBarStyleIos} />
      )}

      {Platform.OS === 'android' && (
        <StatusBar
          barStyle={theme.values.defaultStatusBarStyleAndroid}
          backgroundColor={theme.values.defaultStatusBarAndroidBackgroundColor}
        />
      )}

      {Platform.OS === 'native' && (
          <Provider store={reduxStore}>
            <PersistGate loading={<Spinner />} persistor={persistor}>
              <AppNavigator />
            </PersistGate>
          </Provider>
        ) && <FlashMessage position="top" />}

      {Platform.OS === 'web' && (
        <Provider store={reduxStore}>
          <View style={localStyle.rowContainer}>
            <View style={{ width: 300, height: screenHeight }}>
              <PersistentColumnNavigation navigationRef={navigationRef} />
            </View>
            <View style={{ flex: 1, height: screenHeight }}>
              <NavigationContainer
                ref={navigationRef}
                linking={linking}
                fallback={<Text>Loading...</Text>}
              >
                <AppNavigator />
              </NavigationContainer>
            </View>
          </View>
        </Provider>
      )}
    </View>
    // </ActionSheetProvider>
  );
}

const screenHeight = Dimensions.get('window').height;

const linking = {
  config: {
    screens: {
      [Stacks.SIGNED_IN]: 'user',
      [Stacks.SIGNED_OUT]: '',
      [Routes.CHECK_IN]: 'check-in',
      [Routes.ABOUT]: 'about',
      [Routes.WEBVIEW]: 'webview',
      [Routes.DOCUMENT]: 'document',
      [Routes.ABOUT_APP]: 'aboutApp',
      [Routes.ABOUT_AATM]: 'aboutAatm',
      // [Routes.WALKTHROUGH]: 'walkthrough',
      [Routes.ABOUT_REGISTER]: 'content',
      [Routes.ERSTMELDUNG]: 'erstmeldung',
      [Routes.FIRST_REPORT]: 'first-report',
      [Routes.LEGAL_INFORMATION]: 'legal-information',
      [Routes.LAB_RESULTS]: 'lab-results',
      [Routes.STARTSCREEN]: 'start',
      [Routes.LANDING]: 'landing',
      [Routes.LOGIN]: 'login',
      [Routes.REGISTRATION]: 'registration',
      [Routes.MEDICAL_STAFF]: 'medicalStaff',
      [Routes.OVERVIEW_SURVEY]: 'overview-survey',
      [Routes.SURVEY]: 'survey',
    },
  },
};

/***********************************************************************************************
local styling
***********************************************************************************************/

const localStyle = StyleSheet.create({
  container: {
    ...(Platform.OS === 'native' && { flex: 1 }),
    backgroundColor: '#fff',
  },
  rowContainer: {
    flexDirection: 'row',
    // flexWrap: 'wrap',
    // flex: 1,
    // justifyContent: 'space-between',
    // alignItems: 'center',
  },
});

/***********************************************************************************************
export
***********************************************************************************************/

export default App;
