// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React from 'react';
import {Image, StyleSheet, useWindowDimensions, View} from 'react-native';
import PropTypes from 'prop-types';
import {navigationPropType} from '../../propTypes';

// custom components
// services & config
import {theme} from '../../config';
import RenderHtml from "react-native-render-html";

/***********************************************************************************************
 * component:
 * renders the legal information screen which contains legal information about the study
 *
 * @param {object} props
 * @param {object} props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function AatmTeamScreen({navigation}) {

    let {width} = useWindowDimensions();


    return (<View style={localStyle.wrapper}>
        <View style={{width: 700}}>
            {/* content */}
            <Image source={require('src/assets/images/team.png')}
                   style={{width: 1170 / 2.0, height: 855 / 2.0, align: 'center', alignSelf: 'center', marginBottom:30 }}
                   resizeMode={"contain"}/>
            <RenderHtml contentWidth={width} source={{
                html:
                    '<h1 >Das Team hinter dem Register</h1><p style="font-size: 120%">Das Alpha-1-KIDS-Register wird durch das Team des Alpha-1-Center' +
                    ' der Universitätskinderklinik in Bonn geleitet. Unter der Leitung von Prof. Rainer Ganschow sind an der' +
                    ' Entwicklung auch Dr. David Katzer und Dr. Alexander Weigert beteiligt gewesen. Beide arbeiten als ' +
                    'Kindergastroenterologen in der Kinderklinik und beschäftigen sich wissenschaftlich mit dem' +
                    ' Alpha-1-Antitrypsin-Mangel. Ergänzt wird das ärztliche Team durch Prof. Soyhan Bagci, welcher für die' +
                    ' regelmäßige Auswertung der Daten verantwortlich ist.</p><p style="font-size: 120%">Die technischen Voraussetzungen für das ' +
                    'innovative rein digitale α1-KIDS-Register wurden von Prof. Stephan Jonas und seinem Mitarbeiter Marko' +
                    ' Jovanović vom Institut für Digitale Medizin der Universitätsklinik Bonn geschaffen.</p> '

            }}/>

        </View>
    </View>);
}

AatmTeamScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    wrapper: {
        height: '100%',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: theme.values.defaultBackgroundColor,
    }
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default AatmTeamScreen;
