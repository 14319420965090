// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React, {useEffect, useState} from 'react';
import {
    Dimensions,
    Image,
    Modal,
    Platform,
    StyleSheet,
    Text,
    TouchableOpacity,
    useWindowDimensions,
    View,
} from 'react-native';
import PropTypes from 'prop-types';
import {navigationPropType} from '../propTypes';

// custom components
// this import results in several errors -> should work now
import {Banner, ScrollIndicatorWrapper} from '../components/shared';

// services & config
import translate from '../services/localization';
// import causes appConfig.js:195 Uncaught ReferenceError: __DEV__ is not defined -> should work now
import {appConfig, theme} from '../config';
// check if WalkthroughView works for web -> but not used here anyway
// import WalkthroughView from '../components/checkIn/walkthroughView';
/* even though react-native-paper is supported for web, there is an issue with babel:
 ERROR in ./node_modules/react-native-vector-icons/lib/create-icon-set.js:
 Support for the experimental syntax 'jsx' isn't currently enabled -> should work now*/
import {Portal, Provider} from 'react-native-paper';
// import { blue100 } from 'react-native-paper/lib/typescript/styles/themes/v2/colors';
import {Routes, Stacks} from '../navigation/constants';
// only show startView in native version?
import StartView from '../components/checkIn/startView';
import StartScreenButton from '../components/ui/StartScreenButton';
import {useDispatch, useSelector} from 'react-redux';
import localStorage from '../services/localStorage';
import {setShowStartView} from '../store/user.slice';
// TODO: check if versionCheck works for web
import {versionCheck} from '../services/utils';
import RenderHtml from 'react-native-render-html';

/***********************************************************************************************
 * component:
 * renders the legal information screen which contains legal information about the study
 *
 * @param {object} props
 * @param {object} props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function StartScreen({navigation}) {
    const dispatch = useDispatch();
    useEffect(() => {
        versionCheck(navigation, dispatch);
    }, [])

    const [showButtonsModal, setShowButtonsModal] = useState(false);
    const handlePress = () => {
        setShowButtonsModal(!showButtonsModal);
    };

    const {subjectId, showStartView} = useSelector((state) => state.User);

    const [alreadyRegistered, setAlreadyRegistered] = useState(false);
    const [showAlreadyRegisteredModal, setShowAlreadyRegisteredModal] = useState(false);
    const {isRegistered} = useSelector((state) => state.Globals);
    useEffect(() => {
        localStorage.getIsRegistered().then((isReg) => {
            setAlreadyRegistered(isReg);
        });
    }, [isRegistered]);
    useEffect(() => {
        setShowButtonsModal(!showStartView);
    }, [showStartView]);

    // when component loads handle log in
    useEffect(() => {
        if (subjectId) {
            navigation.replace(Stacks.SIGNED_IN, {screen: Routes.CHECK_IN});
        }
    }, [subjectId, navigation]);

    const AlreadyRegisteredModal = ({visible, onDismiss, onConfirm}) => {
        const handleOkPress = () => {
            onConfirm();
            onDismiss();
        };

        let {width} = useWindowDimensions();

        return (
            <Provider
                style={{
                    alignContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    background: 'red',
                }}
            >
                <Portal
                    style={{
                        alignContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                        background: 'black',
                    }}
                >
                    <Modal
                        style={{
                            alignContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                            flexDirection: 'row',
                            background: 'blue',
                            ...(Platform.OS === 'web' && {width: 800, height: 300}),
                        }}
                        visible={visible}
                        onDismiss={onDismiss}
                        transparent={true}
                    >
                        <View style={localStyle.modalOverlay}/>

                        <View style={localStyle.modalContainer}>

                            {Platform.OS === 'native' && (
                                <RenderHtml
                                    contentWidth={width}
                                    source={{
                                        html:
                                            '<div style="font-size: 1.2em">' +
                                            'Ihre Registrierung ist bereits abgeschlossen. ' +
                                            'Eine erneute Registrierung ist nicht notwendig.<br /><br />' +
                                            'Der Zugangscode befindet sich bereits auf dem Weg zu Ihnen, oder Sie haben diesen ' +
                                            'bereits erhalten.<br /><br />' +
                                            'Sollten Sie den Zugangscode erneut benötigen oder Fragen zu dem weiteren ' +
                                            'Registrierungsvorgang haben, ' +
                                            'zögern Sie nicht uns per E-Mail (<a href="mailto:alpha1kinderzentrum@ukbonn.de">alpha1kinderzentrum@ukbonn.de</a>) zu kontaktieren.' +
                                            '</div>',
                                    }}
                                />
                            )}
                            {Platform.OS === 'web' && (
                                <Text style={localStyle.modalMessage}>
                                    Ihre Registrierung ist bereits abgeschlossen. Eine erneute
                                    Registrierung ist nicht notwendig.{'\n\n'}
                                    Der Zugangscode befindet sich bereits auf dem Weg zu
                                    Ihnen, oder Sie haben diesen bereits erhalten.{'\n\n'}
                                    Sollten Sie den Zugangscode erneut benötigen oder Fragen
                                    zu dem weiteren Registrierungsvorgang haben, zögern Sie
                                    nicht uns zu kontaktieren.
                                </Text>
                            )}

                            <TouchableOpacity
                                style={[
                                    localStyle.modalButtonOutlined,
                                    {width: '80%', marginBottom: 20, marginTop: Platform.OS === 'native' ? 30 : 0},
                                ]}
                                onPress={() => {
                                    navigation.navigate(Routes.REGISTRATION);
                                    handleOkPress();
                                }}
                            >
                                <Text style={localStyle.modalButtonLabelOutlined}>
                                    Trotzdem fortfahren
                                </Text>
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={[localStyle.modalButtonSubmit, {width: '80%'}]}
                                onPress={handleOkPress}
                            >
                                <Text style={localStyle.modalButtonLabel}>Schließen</Text>
                            </TouchableOpacity>
                        </View>
                    </Modal>
                </Portal>
            </Provider>
        );
    };

    return (
        // TODO: check if AlreadyRegisteredModal works for Web
        <View style={{backgroundColor: 'white'}}>
            <AlreadyRegisteredModal visible={showAlreadyRegisteredModal} onDismiss={() => {
                setShowAlreadyRegisteredModal(false);
            }}
                                    onConfirm={() => {
                                        setShowAlreadyRegisteredModal(false);
                                    }}/>

            <View style={localStyle.wrapper}>
                {!showButtonsModal ? (
                    <StartView
                        onDone={() => {
                            console.log('onDone');
                            setShowButtonsModal(true);
                            dispatch(setShowStartView(false));
                        }}
                        onSkip={() => {
                            console.log('onSkip');
                            setShowButtonsModal(true);
                            dispatch(setShowStartView(false));
                        }}
                    />
                ) : (
                    <View style={{backgroundColor: 'white'}}>
                        <View style={{alignContent: 'center', flex: 1, width: 800, marginTop: 50}}>
                            <Banner
                                nav={navigation}
                                title={'Alpha-1-KIDS-App'}
                                subTitle={translate('login').subTitle}
                                noWayBack={true}
                            />

                            <ScrollIndicatorWrapper>
                                {Platform.OS === 'native' && <Image
                                    source={require('../assets/images/illuStartseite.png')}
                                    resizeMode="contain"
                                    style={localStyle.logo}
                                ></Image>
                                }

                                <Text style={localStyle.welcomeText}>Herzlich Willkommen auf der Homepage des
                                    Alpha-1-KIDS-Register, dem Register für Kinder und Jugendliche mit
                                    Alpha-1-Antitrytpsin-Mangel (AATM)!</Text>

                                <StartScreenButton
                                    onPress={() => {
                                        if (alreadyRegistered) {
                                            setShowAlreadyRegisteredModal(true);
                                        } else {
                                            navigation.navigate(Routes.REGISTRATION)
                                        }
                                    }}
                                    title={"Registrieren - 1. Schritt"}
                                    isCompleted={alreadyRegistered}
                                    text={"Registrieren Sie sich jetzt, um Ihren Zugangscode per Post zu erhalten"}/>

                                <StartScreenButton
                                    onPress={() => navigation.navigate(Routes.LOGIN)}
                                    title={'Registrieren - 2. Schritt'}
                                    text={
                                        'Haben Sie Ihren Zugangscode per Post erhalten? Dann schalten Sie jetzt Ihren Zugang frei'
                                    }
                                />

                                <StartScreenButton
                                    onPress={() => navigation.navigate(Routes.WALKTHROUGH)}
                                    title={'Rundgang durch die App'}
                                    text={
                                        'Hier zeigen und erklären wir Ihnen die wichtigsten Funktionen der App'
                                    }
                                />

                                <StartScreenButton
                                    onPress={() => navigation.navigate(Routes.ABOUT_REGISTER)}
                                    title={"Über das Register"}
                                    text={"Erfahren Sie hier etwas darüber, warum das Register so wichtig ist und wie es Ihnen nützt"}/>


                                <StartScreenButton
                                    onPress={() => navigation.navigate(Routes.ABOUT_AATM)}
                                    title={translate('aatmInfo').title}
                                    text={translate('aatmInfo').subTitle}
                                />

                                {Platform.OS === 'native' && (
                                    <TouchableOpacity
                                        style={{
                                            alignItems: 'center',
                                            alignContent: 'center',
                                            marginTop: 15,
                                            marginBottom: 20,
                                        }}
                                        onPress={() => {
                                            setShowButtonsModal(false);
                                        }}
                                    >
                                        <Text>Möchten Sie die Einführung erneut sehen?</Text>
                                    </TouchableOpacity>
                                )}

                                {Platform.OS === 'web' &&
                                    <>

                                        <View
                                            style={{flex: 1, alignItems: 'center'}}>
                                            <Text style={{width: '100%', align: 'center', textAlign: 'center'}}>Laden
                                                Sie sich jetzt die Alpha-1-KIDS auf Ihr Smartphone herunter:</Text>
                                            <View style={{
                                                flex: 1,
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}>
                                                <Image source={require('src/assets/images/qr-mobile.png')}
                                                       style={{height: 200, width: 200}} resizeMode={"contain"}/>
                                                <View style={{flex: 1, flexDirection: 'column'}}>

                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            window.open('https://play.google.com/store/apps/details?id=org.digital_medicine.aatm.app.android')
                                                        }}
                                                    >
                                                        <Image source={require('src/assets/images/googleplay.png')}
                                                               style={{height: 250 / 4, width: 646 / 4}}
                                                               resizeMode={"contain"}/>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            window.open('https://apps.apple.com/de/app/alpha-1-kids/id6476488061')
                                                        }}
                                                    >
                                                        <Image source={require('src/assets/images/appstore.png')}
                                                               style={{height: 250 / 4.5, width: 748 / 4.5}}
                                                               resizeMode={"contain"}/>
                                                    </TouchableOpacity>
                                                </View>
                                            </View>

                                            <TouchableOpacity onPress={() => {
                                                setShowButtonsModal(false);
                                            }}>
                                                <Text style={{width: '100%', align: 'center', textAlign: 'center'}}>Möchten
                                                    Sie die Einführung erneut sehen?</Text>
                                            </TouchableOpacity>
                                        </View>
                                    </>
                                }
                            </ScrollIndicatorWrapper>
                        </View>
                    </View>
                )}
            </View>
        </View>
    );
}

StartScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/
const windowWidth = Dimensions.get('window').width;

const localStyle = StyleSheet.create({
    wrapper: {
        // TODO: check if scaleUiFkt works for web
        paddingBotton: appConfig.scaleUiFkt(20),
        //height: Platform.OS === 'native' ? '100%' : 1000,
        flexDirection: 'column',

        backgroundColor: theme.values.defaultBackgroundColor,
    },
    logo: {
        width: windowWidth,
        aspectRatio: 1.5,
        height: 300,
        backgroundColor: 'black',
        alignSelf: 'center',
    },

    welcomeText: {
        fontSize: theme.fonts.title2.fontSize,
        // fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: appConfig.scaleUiFkt(10),
    },

    button: {
        marginTop: appConfig.scaleUiFkt(5),
        marginStart: appConfig.scaleUiFkt(30),
        marginEnd: appConfig.scaleUiFkt(30),
    },
    buttonSubmit: {
        height: appConfig.scaleUiFkt(60),
        marginBottom: appConfig.scaleUiFkt(10),
    },
    buttonSubmitText: {
        fontSize: appConfig.scaleUiFkt(20),
        color: '#ffffff',
        textAlignVertical: 'center',
    },
    infoText: {
        marginVertical: appConfig.scaleUiFkt(20),
        textAlign: 'justify',
        alignSelf: 'center',
        color: theme.colors.accent4,
        ...theme.fonts.body,
    },

    qrCodeText: {
        fontSize: appConfig.scaleUiFkt(20),
        width: '80%',
        textAlign: 'center',
    },


    modalContainer: {
        backgroundColor: '#fff',
        padding: 20,
        ...(Platform.OS === 'native' && {left: '5%', width: '90%', height: '90%'}),
        ...(Platform.OS === 'web' && {left: '50%', marginLeft: -400, width: 800, height: 500}),
        top: '5%',
        borderRadius: 10,
        position: 'absolute',
    },

    modalOverlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    modalMessage: {
        fontSize: 18,
        marginBottom: 20,
    },

    modalButtonSubmit: {
        ...theme.classes.buttonPrimary,
        bottom: 0,
        marginRight: 10
    },

    modalButtonOutlined: {
        ...theme.classes.buttonPrimaryOutlined,
        bottom: 0,
        marginRight: 10
    },
    modalButtonLabel: {
        ...theme.classes.buttonLabel,
        color: theme.colors.white
    },
    modalButtonLabelOutlined: {
        ...theme.classes.buttonLabel,
        color: theme.colors.primary
    },

});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default StartScreen;
