module.exports = {
  currentEltern: null,
  currentKind: null,
  currentVerlaufsboegenKind: null,
  currentInstrument: null,
  lastLabId: null,
    images: {
        M: {
            uri: require('../assets/images/mother.png')
        },
        V: {
            uri: require('../assets/images/father.png')
        },
        E: {
            uri: require('../assets/images/family.png')
        },
        D: {
            uri: require('../assets/images/diverse.png')
        },
        m: {
            uri: require('../assets/images/son.png')
        },
        w: {
            uri: require('../assets/images/daughter.png')
        },
    }
};
