// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import React, {useEffect} from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { navigationPropType } from '../propTypes';

// components
import WebView from '../components/shared/WebView';

// custom components
import { Banner } from '../components/shared';

// services & config
import { theme } from '../config';
import { Routes, Stacks } from '../navigation/constants';
import {Iframe} from "@bounceapp/iframe";

/**
 * component:
 * renders the webview screen which displays a website within the app
 *
 * @param  {object}    props
 * @param  {object}    props.route the route object provided by 'react-navigation'
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 */
function WebViewScreen({ route, navigation }) {


  // retrieve params passed via navigation
  const { title, screenSubTitle, uri } = route.params;

  const [isReachable, setIsReachable] = useState(false);
  NetInfo.configure({
    reachabilityUrl: endpoints.ping,
    reachabilityTest: async (response) => response.status === 204,
    reachabilityLongTimeout: 60 * 1000, // 60s
    reachabilityShortTimeout: 5 * 1000, // 5s
    reachabilityRequestTimeout: 3 * 1000, // 15s
    reachabilityShouldRun: () => true,
    shouldFetchWiFiSSID: true, // met iOS requirements to get SSID. Will leak memory if set to true without meeting requirements.
    useNativeReachability: false,
  });

  NetInfo.fetch().then((state) => {
    // console.log("Connection type", state.type);
    // console.log("Is connected?", state.isConnected);

    if (Platform.OS === 'android') {
      // console.log("Is reachable?", state.isInternetReachable);
      setIsReachable(state.isInternetReachable);
    } else {
      // TODO https://stackoverflow.com/questions/57296756/how-to-check-internet-connection-in-react-native-application-for-both-ios-and-an
      const unsubscribe = NetInfo.addEventListener((state) => {
        if (
          state.isInternetReachable === true ||
          state.isInternetReachable === false
        ) {
          // console.log("Is reachable?", state.isInternetReachable);
          setIsReachable(state.isInternetReachable);
        }
      });
    }
  });

  let onShouldStartLoadWithRequest = (event) => {
    const isExternalLink =
      Platform.OS === 'ios' ? event.navigationType === 'click' : true;
    if (event.url.slice(0, 4) === 'http' && isExternalLink) {
      Linking.canOpenURL(event.url).then((supported) => {
        if (supported) {
          Linking.openURL(event.url);
        }
      });
      return false;
    }
    return true;
  };


  return (
    <View style={{ height: 10000, backgroundColor: 'white' }}>
      {Platform.OS === 'web' && (
        <Iframe uri={uri} style={{ flex: 1, height: 2000 }} />
      )}
      {Platform.OS === 'native' && (
        <>
          {!isReachable && <InternetConnectionFail />}
          {isReachable && (
            <View>
              <View style={localStyle.wrapper}>
                {/* banner */}
                <Banner
                  nav={navigation}
                  title={title}
                  subTitle={screenSubTitle}
                />

                {/* content */}
                <View style={[localStyle.flexi, localStyle.wrapper]}>
                  <WebView
                    originWhitelist={['*']}
                    source={{ uri }}
                    onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
                  />
                </View>
              </View>
            </View>
          )}
        </>
      )}
      ;
    </View>
  );
}

WebViewScreen.propTypes = {
  navigation: PropTypes.shape(navigationPropType).isRequired,
  route: PropTypes.shape({
    params: PropTypes.shape({
      title: PropTypes.string.isRequired,
      screenSubTitle: PropTypes.string,
      uri: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

/***********************************************************************************************
localStyle
***********************************************************************************************/

const localStyle = StyleSheet.create({
  wrapper: {
    height: '100%',
    flexDirection: 'column',
    backgroundColor: theme.values.defaultBackgroundColor,
  },

  flexi: {
    flex: 1,
  },
});

/***********************************************************************************************
export
***********************************************************************************************/

export default WebViewScreen;
