import React, { useEffect, useRef, useState } from 'react';
import {
  Alert,
  AppState,
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
  Platform,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { navigationPropType } from '../propTypes';
import GLOBAL from '../config/globals';

// components
import { Banner, ScrollIndicatorWrapper, Spinner } from '../components/shared';
import QuestionnaireModal from '../components/questionnaireModal/questionnaireModal';
import CategoriesList from '../components/survey/categoriesList';

// services
import translate, { getLanguageTag } from '../services/localization';
import { appConfig, theme } from '../config';
import exportService from '../services/questionnaireAnalyzer';

// redux actions
import {
  fetchQuestionnaire,
  switchContent,
} from '../store/questionnaire.slice';
import { sendQuestionnaireResponse } from '../store/sharedActions';

import NetInfo, { useNetInfo } from '@react-native-community/netinfo';
import endpoints from '../services/rest/endpoints';
import DashboardButton from '../components/ui/DashboardButton';
import StartScreenButton from '../components/ui/StartScreenButton';
import { formatDateString } from '../services/utils';
// TODO: check if RenderHtml works for web
import RenderHtml from 'react-native-render-html';
import moment from 'moment';
import AppLock from '../components/security/AppLock';
import questionnaireAnalyzer from '../services/questionnaireAnalyzer';
import { setIsBiometryAuthenticated } from '../store/globals.slice';

/***********************************************************************************************
 * renders the survey-screen with the list of all categories, the modal to answer the questionnaire
 * and a button to send the response to the server
 *
 * @param  {object}    props
 * @param  {object}    props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function OverviewSurveyScreen({ navigation }) {
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => state.Globals);
  const { globalCurrentKind } = useSelector((state) => state.User);

  const questionnaireItemMap = useSelector(
    (state) => state.Questionnaire.itemMap,
  );

  const { categories, itemMap } = useSelector((state) => state.Questionnaire);

  const ConditionalScrollIndicatorWrapper = ({ children }) => {
    if (Platform.OS === 'native') {
      return <ScrollIndicatorWrapper>{children}</ScrollIndicatorWrapper>;
    } else {
      return <>{children}</>;
    }
  };

  let linkIds = itemMap ? Object.keys(itemMap) : [];

  NetInfo.configure({
    reachabilityUrl: endpoints.ping,
    reachabilityTest: async (response) => response.status === 204,
    reachabilityLongTimeout: 60 * 1000, // 60s
    reachabilityShortTimeout: 5 * 1000, // 5s
    reachabilityRequestTimeout: 3 * 1000, // 15s
    reachabilityShouldRun: () => true,
    shouldFetchWiFiSSID: true, // met iOS requirements to get SSID. Will leak memory if set to true without meeting requirements.
    useNativeReachability: true,
  });
  const netInfo = useNetInfo();

  const { registryDataVerlaufsbogenKind } = useSelector(
    (state) => state.RegistryData,
  );

  const [currentKind, setCurrentKind] = useState({});
  useEffect(() => {
    GLOBAL.currentKind = globalCurrentKind;
    setCurrentKind(globalCurrentKind);
    setShowErstmeldebogen(false);
    setShowVerlaufsbogen([]);
  }, [globalCurrentKind]);

  const { width } = useWindowDimensions();

  const { isBiometryAuthenticated } = useSelector((state) => state.Globals);
  const { useAppLock } = useSelector((state) => state.User);
  useEffect(() => {
    setAppLock(useAppLock);
  }, [useAppLock]);
  useEffect(() => {
    // the const isAuthenticated is only relevant for native application
    if (Platform.OS === 'native') {
      setIsAuthenticated(isBiometryAuthenticated);
    }
  }, [isBiometryAuthenticated]);
  const [appLock, setAppLock] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [appState, setAppState] = useState('unknown');
  const handleAppStateChange = (newAppState) => {
    console.log('newAppState', newAppState);
    setAppState(newAppState);
    if (
      Platform.OS === 'native' &&
      (newAppState === 'background' || newAppState === 'unknown')
    ) {
      dispatch(setIsBiometryAuthenticated(false));
    }
  };

  Platform.OS === 'native' &&
    useEffect(() => {
      AppState.addEventListener('change', handleAppStateChange);
      return () => {
        AppState.removeEventListener('change', handleAppStateChange);
      };
    }, []);

  const outputItem = (itemSet, item, i) => {
    if (questionnaireAnalyzer.itemIsEmbedded(item, questionnaireItemMap)) {
      return <></>;
    }

    let foundAnswers = itemSet[item.origCode];
    let answer = undefined;

    console.log('HEREWEARE', item.origCode, item.type);

    if (item.type == 'display') {
      answer = '';
      foundAnswers = false;
      if (item.fieldAnnotation.includes('[displaysummaryanswer')) {
        answer = item.fieldAnnotation;
        const summaryTextRegex =
          /\[displaysummaryanswer(\?(.+?))?\|(.+?)(\|(.+?))?\]/g;
        let matches = answer.matchAll(summaryTextRegex);
        let condition = false;

        for (let captureGroups of matches) {
          condition = captureGroups[2];
          if (condition) {
            for (let linkId of linkIds) {
              let origCode = questionnaireItemMap[linkId]?.origCode;

              if (!!origCode && origCode === condition) {
                console.log('displaysummaryanswerabc', origCode);
                let show = questionnaireAnalyzer.checkConditionsOfSingleItem(
                  itemSet[origCode],
                  questionnaireItemMap,
                  registryDataVerlaufsbogenKind,
                );
                if (show) {
                  answer = captureGroups[3];
                  console.log('das ist ein', origCode, itemSet[origCode]);
                  if (
                    origCode === 'kind_gewicht' ||
                    origCode === 'kind_groesse' ||
                    origCode === 'ver_gewicht' ||
                    origCode === 'ver_groesse'
                  ) {
                    if (!itemSet[origCode]) {
                      answer = '(keine Angabe)';
                    }
                  }
                  break;
                } else {
                  answer = captureGroups[5];
                }
              }
            }
          } else {
            answer = captureGroups[3];
          }
        }
        console.log('finalanswer', answer);
      }

      const fieldEmbeddingRegex = /\{(.+?)\}/g;
      let matches = answer.matchAll(fieldEmbeddingRegex);

      if (matches) {
        for (let captureGroups of matches) {
          console.log('captureGroupsB ', captureGroups, answer);
          let embeddedFieldName = captureGroups[1];
          for (let linkId of linkIds) {
            let origCode = itemMap[linkId]?.origCode;
            let ans = itemSet[origCode];
            if (!!origCode && origCode === embeddedFieldName) {
              // let show  = true;

              if (itemMap[linkId].type === 'string') {
                answer = answer.replace(captureGroups[0], ans ?? '');
                foundAnswers = foundAnswers || !!ans;
              } else if (itemMap[linkId].type === 'email') {
                answer = answer.replace(captureGroups[0], ans ?? '');
                foundAnswers = foundAnswers || !!ans;
              } else if (itemMap[linkId].type === 'date') {
                if (itemMap[linkId].fieldAnnotation.includes('[mmyyyy')) {
                  answer = answer.replace(
                    captureGroups[0],
                    ans ? moment(ans).locale('de').format('MM / YYYY') : '',
                  );
                  foundAnswers = foundAnswers || !!ans;
                } else {
                  answer = answer.replace(
                    captureGroups[0],
                    ans ? moment(ans).locale('de').format('DD.MM.YYYY') : '',
                  );
                  foundAnswers = foundAnswers || !!ans;
                }
              } else if (itemMap[linkId].type === 'choice') {
                let answerOptionValueCodingMap = {};
                itemMap[linkId].answerOption.map((answerOption, index) => {
                  answerOptionValueCodingMap[answerOption.valueCoding.code] =
                    answerOption.valueCoding.display;
                });

                let selectedItemCount = 0;
                Object.keys(answerOptionValueCodingMap).forEach((code) => {
                  if (itemSet[origCode + '___' + code] === '1') {
                    selectedItemCount++;
                  }
                });

                if (itemMap[linkId].repeats) {
                  let choiceAnswer =
                    selectedItemCount > 1
                      ? '<ul style="margin: 0; padding-left: 12px; list-style: square inside;">'
                      : '';
                  Object.keys(answerOptionValueCodingMap).forEach((code) => {
                    if (itemSet[origCode + '___' + code] === '1') {
                      choiceAnswer +=
                        selectedItemCount > 1
                          ? '<li>' + answerOptionValueCodingMap[code] + '</li>'
                          : answerOptionValueCodingMap[code];
                      foundAnswers = true;
                    }
                  });
                  choiceAnswer += selectedItemCount > 1 ? '</ul>' : '';
                  answer = answer.replace(captureGroups[0], choiceAnswer);
                } else {
                  answer = answer.replace(
                    captureGroups[0],
                    ans ? answerOptionValueCodingMap[ans] : '',
                  );
                  console.log('answerRadio', ans, answer);
                  foundAnswers = foundAnswers || !!ans;
                }
              } else if (itemMap[linkId].type === 'integer') {
                answer = answer.replace(captureGroups[0], ans);
                foundAnswers = foundAnswers || !!ans;
              } else if (itemMap[linkId].type === 'decimal') {
                answer = answer.replace(captureGroups[0], ans);
                foundAnswers = foundAnswers || !!ans;
              }
              break;
            }
          }
        }
        if (answer === '' || !foundAnswers) {
          return <></>;
        }
      } else {
        return <></>;
      }
    } else if (item.fieldAnnotation.includes('[slider|')) {
      answer =
        "<div style='padding: 5px'><strong>" + foundAnswers + '</strong></div>';
      if (
        itemMap[item.linkId]?.sliderLabels?.left &&
        itemMap[item.linkId]?.sliderLabels?.right
      ) {
        answer +=
          '<small> (Skala von <strong>' +
          itemMap[item.linkId].sliderLabels.left +
          '</strong> bis <strong>' +
          itemMap[item.linkId].sliderLabels.right +
          '</strong>)</small>';
      }
    } else if (item.type === 'string') {
      answer = foundAnswers;
    } else if (item.type === 'email') {
      answer = foundAnswers;
    } else if (item.type === 'date') {
      if (item.fieldAnnotation.includes('[mmyyyy')) {
        answer = moment(foundAnswers).locale('de').format('MM / YYYY');
      } else {
        answer = moment(foundAnswers).locale('de').format('DD.MM.YYYY');
      }
    } else if (item.type === 'choice') {
      let answerOptionValueCodingMap = {};
      item.answerOption.map((answerOption, index) => {
        answerOptionValueCodingMap[answerOption.valueCoding.code] =
          answerOption.valueCoding.display;
      });

      let selectedItemCount = 0;
      Object.keys(answerOptionValueCodingMap).forEach((code) => {
        if (itemSet[item.origCode + '___' + code] === '1') {
          selectedItemCount++;
        }
      });

      if (item.repeats) {
        answer =
          selectedItemCount > 1
            ? '<ul style="margin: 0; padding-left: 12px; list-style: square inside;">'
            : '';
        Object.keys(answerOptionValueCodingMap).forEach((code) => {
          if (itemSet[item.origCode + '___' + code] === '1') {
            answer +=
              selectedItemCount > 1
                ? '<li>' + answerOptionValueCodingMap[code] + '</li>'
                : answerOptionValueCodingMap[code];
            foundAnswers = true;
          }
        });
        answer += selectedItemCount > 1 ? '</ul>' : '';
      } else {
        answer = answerOptionValueCodingMap[foundAnswers];
      }
    } else if (item.type === 'integer') {
      answer = foundAnswers;
    } else if (item.type === 'decimal') {
      answer = foundAnswers;
    }

    if (item.fieldAnnotation?.includes('[multiinput')) {
      let answerItems = Object.values(answer?.split('~ '));
      answer =
        '<ul style="margin: 0; padding-left: 12px; list-style: square inside;">';
      answerItems.forEach((item) => {
        answer += '<li>' + item + '</li>';
      });
      answer += '</ul>';
    }

    let questiontext = item.text;
    if (item.fieldAnnotation.includes('[displaysummarytext|')) {
      questiontext = item.fieldAnnotation;
      const summaryTextRegex = /\[displaysummarytext\|(.+?)\]/g;
      let matches = questiontext.matchAll(summaryTextRegex);
      for (let captureGroups of matches) {
        questiontext = captureGroups[1];
      }
    }

    return foundAnswers || item.type == 'display' ? (
      <View style={{ marginBottom: 10, marginLeft: 40, marginRight: 40 }}>
        <RenderHtml
          contentWidth={width}
          source={{
            html:
              '<div style="background: #efefef; padding: 10px 10px 10px 10px "><div style="font-weight: bold; font-size: 1.2em; ">' +
              questiontext +
              '</div> ' +
              '<div style="font-weight: normal; font-size: 1.2em; color: ' +
              theme.colors.primary +
              '">' +
              (answer !== undefined ? answer : '(keine Angabe)') +
              '</div></div>',
          }}
        />
      </View>
    ) : (
      <></>
    );
  };

  const [showErstmeldebogen, setShowErstmeldebogen] = useState(false);
  const [showVerlaufsbogen, setShowVerlaufsbogen] = useState([]);

  const scrollViewRef = useRef();
  // setting defaults
  let scrollOffset = 0;
  const handleOnScroll = (event) => {
    // just sets the current scrollOffset
    scrollOffset = event.nativeEvent.contentOffset.y;
  };
  const handleScrollTo = (element) => {
    // scrolls to the given element if the scrollView is currently active
    scrollViewRef?.current?.scrollTo({ ...element, animated: true });
  };

  if (Platform.OS === 'native') {
    if (!isAuthenticated && appLock && appState === 'background') {
      return <></>;
    } else if (
      !isAuthenticated &&
      appLock &&
      (appState === 'active' || appState === 'inactive')
    ) {
      return <AppLock />;
    }
  }

  const images = GLOBAL.images;

  return loading ? (
    <Spinner />
  ) : (
    <View
      style={[localStyle.flexi, localStyle.wrapper]}
      testID="OverviewSurveyScreen"
    >
      {/* render the top banner */}
      <Banner nav={navigation} title={translate('survey').title} />

      <ConditionalScrollIndicatorWrapper
        onScroll={handleOnScroll}
        ref={scrollViewRef}
      >
        <View
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 20,
            marginTop: 40,
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            {images?.[currentKind?.kind_geschlecht]?.uri && (
              <Image
                source={images[currentKind.kind_geschlecht].uri}
                resizeMode="contain"
                style={[localStyle.dashModalIcon, { marginRight: 15 }]}
              ></Image>
            )}
            <View
              style={{ flexDirection: 'column', width: '60%', paddingTop: 6 }}
            >
              <Text
                style={[
                  localStyle.familyTitle,
                  {
                    marginLeft: 0,
                    marginTop: 0,
                    marginBottom: 8,
                  },
                ]}
              >
                {currentKind.kind_vorname + ' ' + currentKind.kind_nachname}
              </Text>
              <Text>
                geb. am{' '}
                {formatDateString(new Date(currentKind.kind_geb), {
                  locale: getLanguageTag(),
                })}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginTop: 20 }}>
          <StartScreenButton
            onPress={() => {
              setShowErstmeldebogen(!showErstmeldebogen);
              handleScrollTo({ y: 0, animated: false });
            }}
            title={'Erstmeldebogen'}
            text={
              'Eingetragen am ' +
              formatDateString(
                new Date(currentKind?.kind_datum_eintragung?.substring(0, 10)),
                { locale: getLanguageTag() },
              )
            }
          ></StartScreenButton>
        </View>

        {showErstmeldebogen &&
          categories?.[1].item.map((it, i) => {
            console.log('CK4', it.origCode, currentKind[it.origCode]);
            return outputItem(currentKind, it, i);
          })}

        {registryDataVerlaufsbogenKind?.map((verlaufsbogen, verIdx) => {
          if (verlaufsbogen.ver_kind_id !== currentKind.kind_kind_id) {
            return <></>;
          }

          return (
            <>
              <StartScreenButton
                onPress={() => {
                  if (!showVerlaufsbogen.includes(verIdx)) {
                    setShowVerlaufsbogen([...showVerlaufsbogen, verIdx]);
                  } else {
                    setShowVerlaufsbogen(
                      showVerlaufsbogen.filter((obj) => obj !== verIdx),
                    );
                  }
                }}
                title={'Verlaufsbogen'}
                text={
                  'Eingetragen am ' +
                  formatDateString(
                    new Date(
                      verlaufsbogen.ver_datum_eintragung.substring(0, 10),
                    ),
                    { locale: getLanguageTag() },
                  )
                }
              ></StartScreenButton>
              {showVerlaufsbogen &&
                showVerlaufsbogen.includes(verIdx) &&
                categories?.[2]?.item.map((it, i) => {
                  return outputItem(verlaufsbogen, it, i);
                })}
            </>
          );
        })}
      </ConditionalScrollIndicatorWrapper>
    </View>
  );
}

OverviewSurveyScreen.propTypes = {
  navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
  dashModal: {
    // justifyContent: 'flex-end',
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    borderRadius: 10,
    padding: 20,
    backgroundColor: theme.values.defaultBackgroundColor,
  },
  dashModalIcon: {
    height: 60,
    width: 60,
  },

  buttonDash: {
    marginTop: appConfig.scaleUiFkt(5),
    marginStart: appConfig.scaleUiFkt(30),
    marginEnd: appConfig.scaleUiFkt(30),
  },
  buttonSubmitDash: {
    height: appConfig.scaleUiFkt(60),
    marginBottom: appConfig.scaleUiFkt(10),
  },
  buttonSubmitTextDash: {
    fontSize: appConfig.scaleUiFkt(20),
    color: '#ffffff',
    textAlignVertical: 'center',
  },

  wrapper: {
    backgroundColor: theme.values.defaultBackgroundColor,
  },

  flexi: {
    flex: 1,
  },

  bottom: {
    alignSelf: 'flex-end',
    justifyContent: 'center',
    flex: 1 / 6,
    width: '100%',
  },

  button: {
    ...theme.classes.buttonPrimary,
    bottom: 0,
    width: '80%',
    textAlign: 'center',
  },

  buttonComplete: {
    backgroundColor: theme.values.defaultSendQuestionnaireButtonBackgroundColor,
  },

  buttonAlert: {
    backgroundColor: theme.colors.alert,
  },

  buttonLabel: {
    ...theme.classes.buttonLabel,
  },

  infoText: {
    textAlign: 'center',
    alignSelf: 'center',
    color: theme.colors.accent4,
    ...theme.fonts.body,
  },

  familyTitle: {
    ...theme.fonts.title2,
    marginLeft: 20,
    marginTop: 30,
    color: theme.values.defaultTitleTextColor,
  },
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default OverviewSurveyScreen;
