/**
 * enum for all route names
 */
const Routes = {
  STARTSCREEN: 'Alpha-1-KIDS Register',
  LANDING: 'Landing',
  LOGIN: 'Anmeldung - Alpha-1-KIDS Register',
  CHECK_IN: 'CheckIn',
  SURVEY: 'Survey',
  OVERVIEW_SURVEY: 'OverviewSurvey',
  ABOUT: 'Über - Alpha-1-KIDS Register',
  WEBVIEW: 'Alpha-1-KIDS',
  DOCUMENT: 'Document',
  WALKTHROUGH: 'Rundgang durch die App - Alpha-1-KIDS Register',
  ABOUT_REGISTER: 'Inhalt',
  FIRST_REPORT: 'FirstReport',
  LEGAL_INFORMATION: 'LegalInformation',
  ABOUT_APP: 'Über die App - Alpha-1-KIDS Register',
  ABOUT_AATM: 'Über AATM - Alpha-1-KIDS Register',
  ABOUT_TEAM: 'Über das Team',
  LINKS: 'Wichtige Links',
  TERMS: 'Nutzungsbedingungen',
  REGISTRATION: 'Registrierung - Alpha-1-KIDS Register',
  MEDICAL_STAFF: 'Medizinisches Fachpersonal - Alpha-1-KIDS Register',
  ERSTMELDUNG: 'Erstmeldung - Alpha-1-KIDS Register',
  LAB_RESULTS: 'Laborergebnisse - Alpha-1-KIDS Register',
  LOAD_NEW_VERSION: 'LoadNewVersion',
  MAINTENANCE_MODE: 'Wartungsmodus - Alpha-1-KIDS Register',
};

/**
 * enum for all stacks
 */
const Stacks = {
  SIGNED_IN: 'SignedIn',
  SIGNED_OUT: 'SignedOut',
  FORCE_NEW_VERSION: 'ForceNewVersion',
};

export { Routes, Stacks };
