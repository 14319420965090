// this replaces react-flash-message for web

// const showMessage = (message, position, type) => {
//   window.alert(
//    message,
//   );
// };

const showMessage = (message, [msg, setMsg], [showMsg, setShowMsg]) => {
  if (message.type == 'info') {
    message.backgroundColor = '#5bc0de';
  } else if (message.type == 'success') {
    message.backgroundColor = '#5cb85c';
  } else if (message.type == 'danger') {
    message.backgroundColor = '#d9534f';
  }
  setMsg(message);
  setShowMsg(true);
  if (message.autoHide !== false) {
    setTimeout(() => {
      setShowMsg(false);
    }, message.duration ?? 2000);
  }
};

export default showMessage;
