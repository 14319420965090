// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

// for webview only show limited amount of navigation options

import React, {useState} from 'react';
// navigators need to be wrapped in NavigationContainer, which acts as a context that provides navigation state,
// which can be accessed through props in each component that is in that navigator
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import AboutScreen from '../screens/aboutScreen';
// implemented alternative for webview for web -> redirect to new tab
import WebViewScreen from '../screens/webViewScreen';
import LegalInformationScreen from '../screens/legalInformationScreen';
import LandingScreen from '../screens/landingScreen';

import LoginScreen from '../screens/loginScreen';
import CheckInScreen from '../screens/checkInScreen';
import SurveyScreen from '../screens/surveyScreen';

import AatmInfoScreen from '../screens/texts/aatmInfoScreen';
import AatmTeamScreen from '../screens/texts/aatmTeamScreen';
import LinksScreen from '../screens/texts/linksScreen';
import TermsScreen from '../screens/texts/termsScreen';
import WalkthroughScreen from '../screens/walkthroughScreen';
import AboutRegisterScreen from '../screens/texts/aboutRegisterScreen';
import AboutAppScreen from '../screens/texts/aboutAppScreen';

import { Routes, Stacks } from './constants';
import StartScreen from '../screens/startScreen';
import RegistrationScreen from '../screens/registrationScreen';
import MedicalStaffScreen from '../screens/medicalStaffScreen';
import FirstReportScreen from '../screens/texts/firstReportScreen';
import ErstmeldungScreen from '../screens/erstmeldungScreen';
import DocumentScreen from '../screens/documentScreen';

import OverviewSurveyScreen from '../screens/overviewSurveyScreen';
import LabResultsScreen from '../screens/labResultsScreen';

import {
  Linking,
  Text,
  View,
  Image,
  TouchableOpacity,
  Modal,
  StyleSheet,
} from 'react-native';
import { appConfig, theme } from '../config';
import { TextInput } from 'react-native-paper';
import { loggedInClient } from '../services/rest';

// not supported for web -> only use in native version
// import { showMessage } from 'react-native-flash-message';

import { useSelector } from 'react-redux';


// version check not used here
// import VersionCheck from '../components/shared/VersionCheck';

// icon not used in this file
// import Icon from 'react-native-vector-icons/Ionicons';

/***********************************************************************************************
export and AppContainer-creation
***********************************************************************************************/

// creates the stack-navigator for the navigation while NOT LOGGED IN

function SignedOutView() {
  const Stack = createNativeStackNavigator();
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
      }}
      initialRouteName={Routes.STARTSCREEN}
    >
      <Stack.Screen name={Routes.STARTSCREEN} component={StartScreen} />
      <Stack.Screen name={Routes.LANDING} component={LandingScreen} />
      <Stack.Screen name={Routes.LOGIN} component={LoginScreen} />
      <Stack.Screen name={Routes.WALKTHROUGH} component={WalkthroughScreen} />
      <Stack.Screen name={Routes.ABOUT_REGISTER} component={AboutRegisterScreen} />
      <Stack.Screen name={Routes.ABOUT_AATM} component={AatmInfoScreen} />
      <Stack.Screen name={Routes.LINKS} component={LinksScreen} />
      <Stack.Screen name={Routes.TERMS} component={TermsScreen} />
      <Stack.Screen name={Routes.ABOUT_TEAM} component={AatmTeamScreen} />
      <Stack.Screen name={Routes.ABOUT_APP} component={AboutAppScreen} />
      <Stack.Screen name={Routes.REGISTRATION} component={RegistrationScreen} />
      <Stack.Screen name={Routes.MEDICAL_STAFF} component={MedicalStaffScreen} />
      <Stack.Screen name={Routes.WEBVIEW} component={WebViewScreen} />
      <Stack.Screen name={Routes.ABOUT} component={AboutScreen} />
    </Stack.Navigator>
  );
}

// creates the stack-navigator for the navigation while LOGGED IN

function SignedInView() {
  const Stack = createNativeStackNavigator();
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
      }}
      initialRouteName={Routes.CHECK_IN}
    >
      <Stack.Screen name={Routes.STARTSCREEN} component={StartScreen} />
      <Stack.Screen name={Routes.SURVEY} component={SurveyScreen} />
      <Stack.Screen
        name={Routes.OVERVIEW_SURVEY}
        component={OverviewSurveyScreen}
      />
      <Stack.Screen name={Routes.CHECK_IN} component={CheckInScreen} />
      <Stack.Screen name={Routes.ABOUT} component={AboutScreen} />
      <Stack.Screen name={Routes.WEBVIEW} component={WebViewScreen} />
      <Stack.Screen name={Routes.DOCUMENT} component={DocumentScreen} />
      <Stack.Screen name={Routes.ABOUT_APP} component={AboutAppScreen} />
      <Stack.Screen name={Routes.ABOUT_AATM} component={AatmInfoScreen} />
      <Stack.Screen name={Routes.LINKS} component={LinksScreen} />
      <Stack.Screen name={Routes.TERMS} component={TermsScreen} />
      <Stack.Screen name={Routes.ABOUT_TEAM} component={AatmTeamScreen} />
      <Stack.Screen name={Routes.WALKTHROUGH} component={WalkthroughScreen} />
      <Stack.Screen name={Routes.ABOUT_REGISTER} component={AboutRegisterScreen} />
      <Stack.Screen name={Routes.ERSTMELDUNG} component={ErstmeldungScreen} />
      <Stack.Screen name={Routes.FIRST_REPORT} component={FirstReportScreen} />
      <Stack.Screen name={Routes.MEDICAL_STAFF} component={MedicalStaffScreen} />
      <Stack.Screen
        name={Routes.LEGAL_INFORMATION}
        component={LegalInformationScreen}
      />
      <Stack.Screen name={Routes.LAB_RESULTS} component={LabResultsScreen} />
    </Stack.Navigator>
  );
}


/**
 * creates the app container based on the two stack-navigators
 */
function AppNavigator() {
  const RootStack = createNativeStackNavigator();
  return (
      // <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
        <RootStack.Navigator
          screenOptions={{
            headerShown: false,
            gestureEnabled: false,
          }}
          initialRouteName={Stacks.SIGNED_OUT}
        >
          <RootStack.Screen name={Stacks.SIGNED_IN} component={SignedInView} />
          <RootStack.Screen
            name={Stacks.SIGNED_OUT}
            component={SignedOutView}
          />
        </RootStack.Navigator>
      // </NavigationContainer>
  );
}

export default AppNavigator;
