// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
imports
***********************************************************************************************/

import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import PropTypes from 'prop-types';
import { navigationPropType } from '../propTypes';
import { Button } from 'react-native-paper';

// custom components
import DocumentDropzone from '../components/DocumentDropzone';

// services & config
import { theme } from '../config';
import { Routes, Stacks } from '../navigation/constants';

// TODO: test if upload works
function DocumentScreen({ navigation }) {

  const [value, setValue] = useState(0);

  useEffect(() => {
    return navigation.addListener('focus', () => {
      setValue(value + 1);
    });
  }, [navigation]);

    return (
    <View style={{ backgroundColor: 'white'}}>
        <DocumentDropzone />
        <Button
          icon="arrow-left"
          mode="contained"
          onPress={() => navigation.goBack()}
        >
          Zurück
        </Button>
    </View>
    );
}

DocumentScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
localStyle
***********************************************************************************************/

const localStyle = StyleSheet.create({
  wrapper: {
    height: '100%',
    flexDirection: 'column',
    backgroundColor: theme.values.defaultBackgroundColor,
  },

  flexi: {
    flex: 1,
  },
});

/***********************************************************************************************
export
***********************************************************************************************/

export default DocumentScreen;
