/**********************************************************************************
import
***********************************************************************************************/

import React, {useState} from 'react';
import {
  View,
  StyleSheet,
  Modal,
  Alert,
  Pressable,
  Text,
  useWindowDimensions,
  Image,
  I18nManager,
  Platform,
} from 'react-native';
import PropTypes from 'prop-types';

import { theme } from '../../config';
import RenderHtml from "react-native-render-html";
import {IconButton} from "react-native-paper";
import RNModal from 'react-native-modal';
import translate from '../../services/localization';

function ModalInfo({label = 'Zusätzliche Erklärung\nanzeigen',
                       source = require('../../assets/images/info.png'),
                       infoText, title="Erklärung", confirmButton = {}}) {

  const [modalVisible, setModalVisible] = useState(false);
  const { width } = useWindowDimensions();

  const html = {
    html: '<span style="font-size: 18px;">' + markdownToHtml(infoText) + '</span>'
  }

  return (
    <View style={styles.centeredView}>
      <RNModal
                transparent={true}
        visible={modalVisible}
        style={{ margin: -40, padding: 40 }}
                backdropColor={"black"}
        backdropOpacity={0.7}
        onRequestClose={() => {
                    setModalVisible(!modalVisible);
        }}
      >
        <View
          style={[
            styles.centeredView,
            {
              backgroundColor: 'rgba(0,0,0,0.5)',
            },
          ]}
        >
          <View style={styles.modalView}>
            <View style={[styles.titleWrapper, {position: 'relative', width: '100%'}]}>
              <Text
                style={styles.modalTitle}
                accessibilityRole={translate('accessibility').types.header}
              >{title}</Text>

              <IconButton
                icon="close"
                iconColor={theme.colors.black}
                size={30}
                onPress={() => {
                  setModalVisible(!modalVisible);
                }}
style={{ right: -20, top: 8, position: 'absolute'}}
                containerColor={theme.colors.white}
                mode={'contained'}
              />
            </View>
            <View style={{ marginLeft: 15, marginRight: 15}}>

              <RenderHtml
                                contentWidth={width}
                                source={html}
                            />


                            {!!confirmButton && !!confirmButton.label &&

                                <Pressable
                                    style={[styles.button, styles.buttonBordered]}
                                    onPress={() => {
                                        setModalVisible(!modalVisible);
                                        setTimeout(() => {confirmButton?.onPress?.()}, 500)
                                    }}>

                                    {confirmButton?.source &&
                                        <Image source={confirmButton?.source}
                                           style={{height: 40, width: 40, marginRight: 20}}/>
                                    }

                                    <Text style={[{
                                        marginRight: 10, fontSize: 15,
                                        textAlign: 'left',
                                    }]}>{confirmButton?.label}</Text>
                                </Pressable>

                            }
            </View>

          </View>
        </View>
      </RNModal>

      <Pressable
        style={[styles.button, styles.buttonBordered]}
        onPress={() => setModalVisible(true)}>
        <Image source={source}
          style={{height: 40, width: 40, marginRight: 20}}/>

        <Text style={[{
              marginRight: 10, fontSize: 15,
              textAlign: 'left',
            }]}>{label}</Text>
      </Pressable>
    </View>
  );
}

ModalInfo.propTypes = {
  infoText: PropTypes.string.isRequired,
};

/***********************************************************************************************
local styling
***********************************************************************************************/

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    alignSelf: 'center',
    height: '100%',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    width: 700,
    alignSelf: 'center',
    borderRadius: 20,
    padding: 25,
paddingTop: 0,
    alignItems: 'center',
  },
  button: {
    flexDirection: 'row',
    alignContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    borderRadius: 10,
    padding: 5,
    marginBottom: 20
  },
  buttonOpen: {
    backgroundColor: theme.colors.primary,
  },

  buttonBordered: {
    borderWidth: 3,
    padding: 7,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 10,
    borderColor: theme.colors.primary,
backgroundColor: theme.colors.white
  },
  textStyle: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },

  titleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
    alignItems: 'center',
    width: I18nManager.isRTL ? '100%' : 'auto',

    paddingTop: 10,

    backgroundColor: theme.colors.white,
  },

  modalTitle: {
    ...theme.fonts.header2,
    fontSize: 20,
    paddingTop: 10,
    ...theme.fonts.header1,
    color: theme.values.defaultModalTitleColor,
  },
});

function markdownToHtml(markdown: string): string {

  const htmlTagRegex = /<("[^"]*"|'[^']*'|[^'">])*>/g;
  if (markdown.match(htmlTagRegex)) {
    return markdown;
  }

  const breakRegex = /\r?\n/g;
  markdown = markdown.replace(breakRegex, '<br />');

  // Replace boldface syntax with <strong> tags
  const boldRegex = /\*{2}(.+?)\*{2}/g;
  markdown = markdown.replace(boldRegex, "<strong>$1</strong>");

  // Replace italic syntax with <em> tags
  const italicRegex = /\/\/(.+?)\/\//g;
  markdown = markdown.replace(italicRegex, "<em>$1</em>");

  // Replace underlined syntax with <u> tags
  const underlineRegex = /_{2}(.+?)_{2}/g;
  markdown = markdown.replace(underlineRegex, "<u>$1</u>");

  console.log(markdown);
  return markdown;
}

export default ModalInfo;
