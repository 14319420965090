import * as React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { theme } from '../../config';

const PersonButton = ({
  name,
  image = <></>,
  onPress = () => {},
  img = undefined,
  rotated = false,
}) => (
  <TouchableOpacity onPress={onPress} style={[styles.mainButton]}>
    <View style={{ width: '100%', alignItems: 'center' }}>
      {image}

      <Text style={{color: 'black', marginBottom: 8, textAlign: 'center', marginTop: 20, fontSize: 15 }} numberOfLines={2} >{name}</Text>
    </View>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  mainButton: {
    width: 140,
    height: 160,
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',

    marginLeft: 20,
    marginRight: 10,
    paddingLeft: 5,
    paddingRight: 5,
    marginBottom: 15,
    marginTop: 15,

    borderRadius: 5,
    borderWidth: 0,
    backgroundColor: '#EDEDED',
    paddingTop: 20,
    paddingBottom: 20,
  },
  icon: {
    height: 50,
    width: 50,
  },
  icon_rotated: {
    transform: [{ rotate: '180deg' }],
  },
});

export default PersonButton;
