import React, {useEffect} from 'react';
import {View, StyleSheet, Image} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

// components
// import { Button, Icon } from 'react-native-elements';
import { Button, Icon } from '@rneui/themed';

// redux actions
import {
  submitInstrument,
  switchContent,
} from '../../store/questionnaire.slice';

// services & config
import setAccessibilityResponder from '../../services/setAccessibilityResponder';
import questionnaireAnalyzer from '../../services/questionnaireAnalyzer';
import translate, { initLocalization } from '../../services/localization';
import { appConfig, theme } from '../../config';

// custom components
import ProgressBar from './progressbar';
import { reset } from '../../store/sharedActions';
import {
  fetchRegistryErstmeldebogenEltern,
  fetchRegistryErstmeldebogenKind,
} from '../../store/registry.slice';
import * as RNLocalize from 'react-native-localize';
// localStorage not needed in this file for now
// import localStorage from '../../services/localStorage';
import { reduxStore } from '../../store';
import { init } from '../../store/globals.slice';
// import SplashScreen from 'react-native-splash-screen';
import GLOBAL from '../../config/globals';

/***********************************************************************************************
 * component
 * creates the bottom-navigation-bar of the modal
 *
 * @param {object}                props the props of this component
 * @param {function}              props.hideModal a callback to hide the modal
 * @param {React.RefObject<any>}  props.modalTitleRef a reference object of the modal title for a11y purposes
 * @param {function}              props.handleScrollTo a callback to scroll to the top when switching between pages
 **********************************************************************************************/
export default function BottomBar({
  modalTitleRef,
  handleScrollTo,
  handleForwardPress,
  hideModal,
}) {
  const dispatch = useDispatch();

  // get data from state

  const {pageIndex, categoryIndex, itemMap, categories, retrieveDataCycle} = useSelector(
    (state) => state.Questionnaire,
  );
const {globalCurrentKind} = useSelector(
        (state) => state.User,
    );

    useEffect(() => {
        GLOBAL.currentKind = globalCurrentKind;
    }, [globalCurrentKind]);

  useEffect(() => {
    console.log('retrieveDataCycle changed ' + retrieveDataCycle);
    console.log('dispatch erstmeldebogen eltern')
    dispatch(fetchRegistryErstmeldebogenEltern({
      subjectId,
    }));
  }, [retrieveDataCycle]);

  useEffect(() => {
    console.log('dispatch erstmeldebogen kind')
    dispatch(fetchRegistryErstmeldebogenKind({
      subjectId,
    }));
  }, [retrieveDataCycle]);

  const {
    subjectId,
  } = useSelector((state) => state.User);

  // check whether the current page has been completely answered
  const completed =
    itemMap[categories[categoryIndex].item[pageIndex - 1].linkId].done;

  const required =
      itemMap[categories[categoryIndex].item[pageIndex - 1].linkId].required;

  const totalTime = questionnaireAnalyzer.calculateTotalTime(categories, categoryIndex);

  /**
   * handler for the 'back' button
   */
  const handleBackPress = () => {
    setAccessibilityResponder(modalTitleRef);
    let index = pageIndex - 1;
    while (index >= 0) {
      if (
        questionnaireAnalyzer.checkConditionsOfSingleItem(
categories[categoryIndex].item[index - 1],
                    itemMap,
        ) && !questionnaireAnalyzer.itemIsEmbedded(
          categories[categoryIndex].item[index - 1],
          itemMap,
        )
      ) {
        dispatch(switchContent({pageIndex: index}));
        return handleScrollTo({y: 0, animated: false});
      }
      index -= 1;
    }
  };

  const progress = () => {
    return appConfig.useStrictModeProgressBar
        ? questionnaireAnalyzer.calculatePageProgress(
            categories,
            categoryIndex,
            pageIndex,
            itemMap,
        )
        : pageIndex / categories[categoryIndex].item.length
  }

  const etl = () => {
    console.log(progress())
    console.log(1 - progress())
    console.log(totalTime)
    return Math.round((1 - progress()) * totalTime);
  }

const isStop = (questionnaireItem) => {
        let ret = questionnaireItem?.fieldAnnotation?.includes('[stop]');
        if (
            questionnaireItem.type === 'display'
        ) {
            const fieldEmbeddingRegex = /\{(.+?)\}/g;
            let matches = questionnaireItem.text.matchAll(fieldEmbeddingRegex);
            if (matches) {
                for (let captureGroups of matches) {
                    let embeddedFieldName = captureGroups[1];
                    console.log('checkforstopX', embeddedFieldName)
                    for (let lid = 0; lid < categories[categoryIndex].item.length; lid++) {
                        console.log('lidA', lid);
                        let origCode = categories[categoryIndex].item[lid]?.origCode;
                        console.log('origCode', origCode);
                        if (!!origCode && origCode === embeddedFieldName) {
                            if (categories[categoryIndex].item[lid].type === 'display') {
                                console.log('checkforstopY', origCode)
                                ret = ret || (
                                    questionnaireAnalyzer.checkConditionsOfSingleItem(
                                        categories[categoryIndex].item[lid],
                                        itemMap
                                    ) &&
                                    isStop(categories[categoryIndex].item[lid])
                                );
                                console.log('checkforstopYY', ret)
                            } else {
                                console.log('checkforstopZ', origCode)
                                ret = ret || (
                                    questionnaireAnalyzer.checkConditionsOfSingleItem(
                                        categories[categoryIndex].item[lid],
                                        itemMap
                                    ) &&
                                    categories[categoryIndex].item[lid].fieldAnnotation?.includes('[stop]')
                                );
                                console.log('checkforstopZZ', ret)
                            }
                        }
                    }
                }
            }
        }
        return ret;
    }

const getTitle = () => {
        if (isStop(categories[categoryIndex].item[pageIndex - 1])) {
            return 'Schließen';
        }

        return !categories[categoryIndex].item[pageIndex - 1]?.fieldAnnotation?.includes('[submitsingle]') ? "Weiter" : 'Abschicken';
    }
  return (
    <View
      style={
        localStyles.bottomBarWrapperWithShadow
      }
      testID="BottomBar"
    >

      {/*{required &&*/}
      {/*    <Text style={[{ marginStart: 10, color: 'red', fontWeight: 'bold', marginBottom: 10 }]}>(Pflichtfrage)</Text>}*/}

            <View style={localStyles.bottomBarButtons}>
        {/* the left navigational button; hidden if on page 1 */}
        
        <Button
          type="clear"
          title={"Zurück"}
          disabled={pageIndex === 1}
          disabledStyle={localStyles.disabledButton}
          titleStyle={!completed && required ? localStyles.completeButtonTitleDisabled : localStyles.completeButtonTitle}
          accessibilityLabel={translate('accessibility').back}
          accessibilityRole={translate('accessibility').types.button}
          accessibilityHint={
            translate('accessibility').questionnaire.leftButtonHint
          }
          onPress={handleBackPress}
          iconRight={false}
          icon={
            <View>
                <Image source={require('src/assets/images/schrittZurueck.png')}
                        style={localStyles.btnNext}/>
            </View>
          }
          testID="BottomBar_confirm_btn"
        />

        {/* the confirmation button in the middle - its color depends on checkCurrentPageState() */}
        <Button
            type="clear"
            title={getTitle()}
            // disabled={!completed && required}
            titleStyle={!completed && required ? localStyles.completeButtonTitleDisabled : localStyles.completeButtonTitle}
            accessibilityLabel={
              !completed && required
                  ? translate('accessibility').questionnaire.middleButtonUnfinished
                  : translate('accessibility').questionnaire.middleButtonFinished
            }
            accessibilityRole={translate('accessibility').types.button}
            accessibilityHint={
              translate('accessibility').questionnaire.middleButtonHint
            }
            onPress={handleForwardPress}
            iconRight={true}
            icon={
              <View>
                <Image source={require('src/assets/images/schrittWeiter.png')}
                       style={!completed && required ? localStyles.btnNext : localStyles.btnNext}/>
              </View>
            }
            testID="BottomBar_confirm_btn"
        />
      </View>
    </View>
  )
        ;
}

BottomBar.propTypes = {
  modalTitleRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.shape({})}),
  ]).isRequired,
  handleScrollTo: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

/***********************************************************************************************
localStyle
***********************************************************************************************/

const localStyles = StyleSheet.create({
  bottomBarWrapper: {
    backgroundColor: theme.values.defaultModalBottomBarBackgroundColor,
  },

  bottomBarWrapperWithShadow: {
    // backgroundColor: theme.values.defaultModalBottomBarBackgroundColor,
    // shadowColor: '#000',
    // shadowOffset: { width: 0, height: 2 },
    // shadowOpacity: 0.8,
    // shadowRadius: 2,
    // elevation: 15,
  },

  bottomBarButtons: {
    
        borderTopWidth: 1,
        borderTopColor: theme.colors.accent2,

    flexWrap: 'nowrap',
    alignItems: 'center',
    alignContent: 'flex-end',
    textAlign: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingStart: 20,
    paddingEnd: 20,
  },

  disabledButton: {
    opacity: 0,
  },
  completeButtonTitle: {
    fontSize: 18,
    paddingRight: 15,
    paddingLeft: 15,
    color: theme.values.defaultSurveyIconCompletedColor,
  },

  completeButtonTitleDisabled: {
    fontSize: 18,
    paddingRight: 15,
    paddingLeft: 15,
    color: theme.colors.accent1,
  },

  btnNext: {
    width: 44,
    height: 44,
    resizeMode: 'contain',
  },
});
