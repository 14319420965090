import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { guestClient } from '../services/rest';
import kioskApi from '../config/kioskApiConfig';
import { setAvailableLanguages } from '../services/localization';

import { sendCredentials, updateLanguage, updateUser } from './user.slice';
import { fetchQuestionnaire } from './questionnaire.slice';
// This import creates module not found errors in node_modules and build error for web. -> should work now
import {
  reset,
  resetAnswers,
  sendQuestionnaireResponse,
  sendReport,
} from './sharedActions';
import localStorage from '../services/localStorage';

// services & config

const getLanguages = createAsyncThunk(
  'globals/GET_LANGUAGES',
  async (_, thunkApi) => {
    const languages = await (kioskApi.active
      ? kioskApi.getLanguages()
      : guestClient.getLanguages());
    setAvailableLanguages(languages);
    return thunkApi.fulfillWithValue(languages);
  },
);

const setIsRegistered = createAsyncThunk(
  'globals/SET_IS_REGISTERED',
  async (isRegistered, thunkApi) => {
    await localStorage.persistIsRegistered(isRegistered);
    return thunkApi.fulfillWithValue(isRegistered);
  },
);

const setBLEBatteryLevel = createAsyncThunk(
  'globals/SET_BLE_BATTERY_LEVEL',
  async (batteryLevel, thunkApi) => {
    return thunkApi.fulfillWithValue(batteryLevel);
  },
);

const setBLEBufferSize = createAsyncThunk(
  'globals/SET_BUFFER_SIZE',
  async (bufferSize, thunkApi) => {
    return thunkApi.fulfillWithValue(bufferSize);
  },
);

const setNewVersionURL = createAsyncThunk(
  'globals/SET_NEW_VERSION_URL',
  async (newVersionURL, thunkApi) => {
    return thunkApi.fulfillWithValue(newVersionURL);
  },
);

const setBLErssi = createAsyncThunk(
  'globals/SET_BLE_RSSI',
  async (batteryLevel, thunkApi) => {
    return thunkApi.fulfillWithValue(batteryLevel);
  },
);

const setBLEDevice = createAsyncThunk(
  'globals/SET_BLE_DEVICE',
  async (device, thunkApi) => {
    console.log('setting connected BLE device');
    console.log(device);
    console.log('device id');
    console.log(device.id);
    console.log(device.name);
    return thunkApi.fulfillWithValue(device);
  },
);

const setBLEDeviceState = createAsyncThunk(
  'globals/SET_BLE_DEVICE_STATE',
  async (batteryLevel, thunkApi) => {
    return thunkApi.fulfillWithValue(batteryLevel);
  },
);
const setIsBiometryAuthenticated = createAsyncThunk(
  'globals/IS_BIOMETRY_AUTHENTICATED',
  async (isBiometryAuthenticated, thunkApi) => {
    return thunkApi.fulfillWithValue(isBiometryAuthenticated);
  },
);

/**
 * this part of the global state handles the loading state and all errors (mostly network related) that might occur
 */
const GlobalsSlice = createSlice({
  name: 'globals',
  initialState: {
    loading: true,
    error: null,
    availableLanguages: null,
    BLEBatteryLevel: null,
    BLEdevice: null,
    BLEdeviceState: null,
    BLErssi: null,
    BLEbufferSize: null,
    isBiometryAuthenticated: false,
    lastSubmitSingle: {},
    isRegistered: false,
    newVersionURL: null,
  },
  reducers: {
    INIT: (state) => ({ ...state, loading: false }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendCredentials.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(sendCredentials.fulfilled, (state) => ({
        ...state,
        loading: false,
        error: null,
      }))
      .addCase(sendCredentials.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error,
      }))
      .addCase(updateUser.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(updateUser.fulfilled, (state) => ({
        ...state,
        loading: false,
        error: null,
      }))
      .addCase(updateUser.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error,
      }))
      .addCase(updateLanguage.pending, (state) => ({ ...state, loading: true }))
      .addCase(updateLanguage.fulfilled, (state) => ({
        ...state,
        loading: false,
        error: null,
      }))
      .addCase(updateLanguage.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error,
      }))
      .addCase(fetchQuestionnaire.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(fetchQuestionnaire.fulfilled, (state) => ({
        ...state,
        loading: false,
        error: null,
      }))
      .addCase(fetchQuestionnaire.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error,
      }))
      .addCase(sendQuestionnaireResponse.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(sendQuestionnaireResponse.fulfilled, (state, action) => {
        let lss = {
          ...state.lastSubmitSingle,
        };
        if (action.payload.instrument_name) {
          lss[action.payload.instrument_name] = new Date();
        }

        return {
          ...state,

          retrieveDataCycle: Math.random(),

          lastSubmitSingle: lss,
          loading: false,
          error: null,
        };
      })
      .addCase(resetAnswers.fulfilled, (state) => ({
        ...state,
        loading: false,
        error: null,
      }))
      .addCase(sendQuestionnaireResponse.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.payload ? action.payload.error : action.error,
        };
      })
      .addCase(reset.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(reset.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action?.payload?.error,
      }))
      .addCase(reset.fulfilled, (_state) => ({
        loading: false,
        error: null,
        availableLanguages: null,
      }))
      .addCase(sendReport.pending, (state) => ({ ...state, loading: true }))
      .addCase(sendReport.fulfilled, (state) => ({
        ...state,
        loading: false,
        error: null,
      }))
      .addCase(sendReport.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload.error,
      }))
      .addCase(getLanguages.fulfilled, (state, action) => ({
        ...state,
        availableLanguages: action.payload,
      }))
      .addCase(setBLEBatteryLevel.fulfilled, (state, action) => ({
        ...state,
        BLEBatteryLevel: action.payload,
      }))
      .addCase(setBLEDeviceState.fulfilled, (state, action) => ({
        ...state,
        BLEdeviceState: action.payload,
      }))
      .addCase(setBLEDevice.fulfilled, (state, action) => ({
        ...state,
        BLEdevice: action.payload,
      }))
      .addCase(setBLErssi.fulfilled, (state, action) => ({
        ...state,
        BLErssi: action.payload,
      }))

      .addCase(setBLEBufferSize.fulfilled, (state, action) => ({
        ...state,
        BLEbufferSize: action.payload,
      }))

      .addCase(setIsBiometryAuthenticated.fulfilled, (state, action) => ({
        ...state,
        isBiometryAuthenticated: action.payload,
      }))
      .addCase(setIsRegistered.fulfilled, (state, action) => ({
        ...state,
        isRegistered: action.payload,
      }))
      .addCase(setNewVersionURL.fulfilled, (state, action) => ({
        ...state,
        newVersionURL: action.payload,
      }))
      .addDefaultCase((state) => ({ ...state }));
  },
});

export default GlobalsSlice.reducer;
export {
  getLanguages,
  setBLEBatteryLevel,
  setBLEDevice,
  setBLEDeviceState,
  setBLErssi,
  setBLEBufferSize,
  setIsBiometryAuthenticated,
  setIsRegistered,
  setNewVersionURL,
};
export const { INIT: init } = GlobalsSlice.actions;
