import React, {useEffect, useState} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {Dimensions, Modal, Platform, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View, Picker} from 'react-native';
import { unstable_createElement } from 'react-native-web';

import PropTypes from 'prop-types';
import {itemPropType} from '../../../propTypes';

// components
// import {Button, Input} from 'react-native-elements';
import { Button } from '@rneui/themed';

// redux actions
import {setAnswer} from '../../../store/questionnaire.slice';

// services & config
import {theme} from '../../../config';
import exportService from '../../../services/questionnaireAnalyzer';
import translate from '../../../services/localization';

import SharedStyles from './sharedStyles';
import RenderHtml from "react-native-render-html";
import ModalInfo from "../../../components/questionnaireModal/modalInfo";
import {stripTags, yyyymmddToDate} from "../../../services/utils";
import debounce from "lodash.debounce";
// TODO: check if react-numeric-input works as well as react-native-numeric-input -> try textinput instead
import NumericInput from 'react-numeric-input';
// check if custom monthpicker (implemented below) works well
// import MonthPicker from "react-native-month-year-picker";
// TODO: check quality of used alternative for RNDateTimePicker for web: https://www.reactnativepaperdates.com/
// import RNDateTimePicker from '@react-native-community/datetimepicker';
import { DatePickerModal } from 'react-native-paper-dates';
// import DatePicker from 'react-date-picker';
// import Picker from 'react-month-picker'

import { TextInput } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';

import moment from 'moment';
import GLOBAL from '../../../config/globals';
/***********************************************************************************************
 * component
 * renders a questionnaire item as date input
 *
 * @param {object} props
 * @param {QuestionnaireItem} props.item the item to be rendered
 **********************************************************************************************/

/**
 * debounce the update of the global state for slightly better performance;
 * instead of dispatching an action after each keystroke, we wait for 350ms
 * should another keystroke occur before 350ms have passed, the previous actions is interrupted
 * and a new action with the updated parameters is enqueued
 *
 */
const setGlobalAnswer = debounce((item, retVal, dispatch) => {
  dispatch(setAnswer({ answer: retVal, linkId: item.linkId }));
}, 150);

export default function DateInput({
  item,
  initValue,
  commitInitValue = false,
  handleForwardPress,
}) {
  console.log('initValue');
  console.log(initValue);
  // internal state which controls, whether the datepicker is shown
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showMonthYearPicker, setShowMonthYearPicker] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [localValue, setLocalValue] = useState(initValue);

  const minDate = item.minVal
    ? item.minVal.includes('now') || item.minVal.includes('today')
      ? new Date()
      : yyyymmddToDate(item.minVal)
    : null;
  const maxDate = item.maxVal
    ? item.maxVal.includes('now') || item.maxVal.includes('today')
      ? new Date()
      : yyyymmddToDate(item.maxVal)
    : null;
  if (maxDate) {
    maxDate.setHours(23);
    maxDate.setMinutes(59);
    maxDate.setSeconds(59);
  }
  if (maxDate && initValue) {
    let maxDateS = moment(maxDate).locale('de').format('YYYY-MM-DD');
    if (maxDateS < initValue) {
      initValue = maxDateS;
    }
  }

  const completed = useSelector((state) => {
    return state.Questionnaire.itemMap[item.linkId].done;
  });

  // get currentValue from state
  // const globalValue = useSelector((state) => {
  //   if (item.type == 'time') {
  //     return (
  //       state.Questionnaire.itemMap[item.linkId].answer?.[0]?.valueTime ??
  //       initValue
  //     );
  //   }
  //   if (item.type == 'date') {
  //     console.log(
  //       'valueDate global',
  //       typeof state.Questionnaire.itemMap[item.linkId].answer?.[0]?.valueDate,
  //     );

  //     return (
  //       state.Questionnaire.itemMap[item.linkId].answer?.[0]?.valueDate ??
  //       initValue
  //     );
  //   }
  // });
  // console.log('globalValue');
  // console.log(globalValue);

  const zeroPad = (num, places) => String(num).padStart(places, '0');

  let calculateAnswer = (date: Date, time: string) => {
    // console.log('calculateAnswer');

    let errorMsg = '';

    let ret = null;
    if (item.type == 'time' && time) {
      ret = { valueTime: time };
    } else if (item.type == 'date' && date) {
      if (!!minDate && minDate > date) {
        errorMsg +=
          'Die Eingabe muss mindestens ' +
          minDate.toLocaleString('de-DE', { timeZone: 'UTC' }) +
          ' sein.\n';
      }
      if (!!maxDate && maxDate < date) {
        console.log('maxDate');
        console.log(maxDate);
        errorMsg +=
          'Die Eingabe darf höchstens ' +
          maxDate.toLocaleString('de-DE', { timeZone: 'UTC' }) +
          ' sein.\n';
      }
      ret = {
        valueDate: !errorMsg ? exportService.getFormattedDate(date) : null,
      };
    } else if (item.type == 'datetime' && date) {
      date.setHours(localHour);
      date.setMinutes(localMinute);
      if (!!minDate && minDate > date) {
        errorMsg +=
          'Die Eingabe muss mindestens ' +
          minDate.toLocaleString('de-DE', { timeZone: 'UTC' }) +
          ' sein.\n';
      }
      if (!!maxDate && maxDate < date) {
        errorMsg +=
          'Die Eingabe darf höchstens ' +
          maxDate.toLocaleString('de-DE', { timeZone: 'UTC' }) +
          ' sein.\n';
      }
      ret = {
        valueDate: !errorMsg ? exportService.getFormattedDate(date) : null,
      };
    } else if (item.type == 'datetime' && time) {
      let h = parseInt(time.split(':')[0]);
      let m = parseInt(time.split(':')[1]);
      localDate.setHours(h);
      localDate.setMinutes(m);
      if (!!minDate && minDate > localDate) {
        errorMsg +=
          'Die Eingabe muss mindestens ' +
          minDate.toLocaleString('de-DE', { timeZone: 'UTC' }) +
          ' sein.\n';
      }
      if (!!maxDate && maxDate < localDate) {
        console.log('maxDate');
        console.log(maxDate);
        errorMsg +=
          'Die Eingabe darf höchstens ' +
          maxDate.toLocaleString('de-DE', { timeZone: 'UTC' }) +
          ' sein.\n';
      }
      ret = {
        valueDateTime: !errorMsg
          ? exportService.getFormattedDate(localDate) + ' ' + time
          : null,
      };
    } else if (item.type == 'time') {
      ret = {
        linkId: item.linkId,
        answerTime: null,
      };
    } else if (item.type == 'date') {
      ret = {
        linkId: item.linkId,
        answerDate: null,
      };
    } else if (item.type == 'datetime') {
      ret = {
        linkId: item.linkId,
        answerDateTime: null,
      };
    }

    setErrorMsg(errorMsg);

    return ret;
  };

  // const [showTimePicker, setShowTimePicker] = useState(false);
  const dispatch = useDispatch();
  // get currentDate from state

  // useEffect(
  //     () => {
  //         // MJ if a global value is set, i.e. data is already availabe, set the answer for this item
  //         // effectively, setting its status to done
  //         if (currentDate) {
  //             dispatch(setAnswer({
  //                 linkId: item.linkId,
  //                 answer: {valueDate: exportService.getFormattedDate(currentDate)},
  //             }));
  //         }
  //     },
  //     [currentDate]);

  console.log(item.type);
  let initDate = '';
  let initHour = 0;
  let initMinute = 0;
  let currentDate;
  if (item.type == 'date') {
    currentDate = useSelector((state) => {
      return state.Questionnaire.itemMap[item.linkId].answer?.[0]?.valueDate;
    });
    initDate = new Date(Date.parse(currentDate ?? initValue));
    console.log('parsedInitDate', initDate);
    console.log('initValueDate', initValue);
  }
  if (item.type == 'time') {
    currentDate = useSelector(
      (state) =>
        state.Questionnaire.itemMap[item.linkId].answer?.[0]?.valueTime,
    );
    if (currentDate) {
      initHour = parseInt(currentDate.split(':')[0]);
      initMinute = parseInt(currentDate.split(':')[1]);
    }
  }
  if (item.type == 'datetime') {
    currentDate = useSelector(
      (state) =>
        state.Questionnaire.itemMap[item.linkId].answer?.[0]?.valueDateTime,
    );
    if (currentDate) {
      const parts = currentDate.substring(0, 10).split('-'); // Split the string into parts

      if (parts.length !== 3) {
        throw new Error('Invalid date format');
      }

      const year = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // JavaScript months are 0-indexed
      const day = parseInt(parts[2], 10);

      initDate = new Date(year, month, day);

      let initTime = currentDate.split(' ');
      if (initTime.length == 2) {
        initHour = parseInt(initTime[1].split(':')[0]);
        initMinute = parseInt(initTime[1].split(':')[1]);
      } else {
        initHour = 0;
        initMinute = 0;
      }
    } else {
      initDate = new Date();
      initHour = initDate.getHours();
      initMinute = initDate.getMinutes();
    }
  }

  const [localHour, setLocalHour] = useState(initHour);
  const [localMinute, setLocalMinute] = useState(initMinute);
  const [localDate, setLocalDate] = useState(new Date(Date.parse(initDate)));

  console.log('initDate', initDate, typeof initDate);
  console.log(typeof localDate);

  const { width } = useWindowDimensions();
  const source = {
    html:
      '<div style="font-weight: bold; font-size: 1.4em">' +
      markdownToHtml(item.text) +
      '</div>',
  };

  let hourHint = 'Uhr';
  let minuteHint = 'Min.';
  let maxHour = 23;

  console.log('item.fieldAnnotation');
  console.log(item.fieldAnnotation);
  if (item.type == 'time') {
    if (item.fieldAnnotation.includes('[validtime|MM:SS')) {
      hourHint = 'Min.';
      minuteHint = 'Sek.';
      maxHour = 59;
    }
  }

  useEffect(() => {
    if (!!initValue && commitInitValue && item.type == 'date') {
      dispatch(
        setAnswer({
          linkId: item.linkId,
          answer: calculateAnswer(initDate, null),
        }),
      );
    }
  }, [initValue]);

  // for DatePickerModal
  //   const [date, setDate] = React.useState(new Date());
  const onDismissSingle = React.useCallback(() => {
    setShowDatePicker(false);
  }, [setShowDatePicker]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setShowDatePicker(false);
      //   setDate(params.date);
      if (params.date) {
        setLocalDate(params.date);
        // console.log("correct params", params.date, ' localDate: ', localDate);
        // dispatch(
        //   setAnswer({
        //     linkId: item.linkId,
        //     answer: calculateAnswer(params.date, null),
        //   }),
        // );
      }
    },
    [setShowDatePicker],
    // [setDate],
    [setLocalDate],
  );

  useEffect(() => {
    console.log("correct params", ' localDate: ', localDate);
    dispatch(
      setAnswer({
        linkId: item.linkId,
        answer: calculateAnswer(localDate, null),
      }),
    );
  }, [localDate]);
 
  // params for custom Month Picker for web
  const months = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']; 
  const years = Array.from({ length: 150 }, (_, k) => new Date().getFullYear() - k);
  const [selectedMonth, setSelectedMonth] = useState(months[localDate.getMonth()]);
  const [selectedYear, setSelectedYear] = useState(localDate.getFullYear());
  
  const [monthPickerItems, setMonthPickerItems] = useState(months);

  useEffect(() => {
    if (selectedYear == new Date().getFullYear()) {
      setMonthPickerItems(months.slice(0, new Date().getMonth() + 1));
    } else {
      setMonthPickerItems(months);
    }
  }, [selectedYear]);

  const dispatchDate = () => {
    let date = new Date(selectedYear, months.indexOf(selectedMonth));
    console.log('dispatchDate', months.indexOf(selectedMonth), ' ', date);
    if (!date) {
      return;
    }
    setLocalDate(date);
    dispatch(
      setAnswer({
        linkId: item.linkId,
        answer: calculateAnswer(date, null),
      }),
    );
  }

  useEffect(() => {
    if (showMonthYearPicker) {
      dispatchDate();
    }
  }, [selectedMonth, selectedYear, showMonthYearPicker])


  return (
    <>
      <View style={SharedStyles.modalInput}>
        {/* title */}
        <RenderHtml contentWidth={width} source={source} />

        {!!item.fieldAnnotation && !!stripTags(item.fieldAnnotation) && (
          <ModalInfo infoText={stripTags(item.fieldAnnotation)} />
        )}

        {/* android datepicker */}

        <SafeAreaView
          style={
            Platform.OS === 'ios' && showMonthYearPicker
              ? { marginTop: 140 }
              : {}
          }
        >
          {(item.type == 'date' || item.type == 'datetime') && (
            <TouchableOpacity
              onPress={() => {
                if (item.fieldAnnotation.includes('[mmyyyy]')) {
                  setShowMonthYearPicker(true);
                } else {
                  setShowDatePicker(true);
                }
              }}
              // accessibilityLabel={ }
              // accessibilityRole={translate('accessibility').types.button}
              // accessibilityHint={translate('accessibility').questionnaire.dateFieldHint}
              testID="overlay"
            >
              <TextInput
                containerStyle={SharedStyles.modalContainer}
                placeholder={translate('login').inputPlaceholderTime}
                value={
                  completed
                    ? exportService.getFormattedDate(
                        localDate ?? initValue,
                        item.fieldAnnotation.includes('[mmyyyy]')
                          ? 'mmyyyy'
                          : true,
                      )
                    : 'Auswahl tätigen'
                }
                style={{
                  textAlign:
                    item.type == 'integer' || item.type == 'decimal'
                      ? 'right'
                      : 'left',
                  backgroundColor: theme.colors.white,
                  marginTop: 15,
                }}
                editable={false}
                left={<TextInput.Icon icon="calendar" />}
                pointerEvents="none"
                testID="chosenDate"
              />
            </TouchableOpacity>
          )}
          {showMonthYearPicker && (
            <View style={{ flexDirection: 'row', paddingTop: '5px' }}>
              <Picker style={{ marginRight: '5px' }} selectedValue={selectedMonth} onValueChange={(month) => setSelectedMonth(month)}>
                {monthPickerItems.map((month, index) => {
                  return <Picker.Item key={index} label={month} value={month} />
                })}
              </Picker>
              <Picker style={{ marginRight: '5px' }} selectedValue={selectedYear} onValueChange={(year) => setSelectedYear(year)}>
                {years.map((year, index) => {
                  return <Picker.Item key={index} label={year} value={year} />
                })}
              </Picker>
              <Button buttonStyle={{ backgroundColor: theme.colors.primary }} onPress={() => setShowMonthYearPicker(false)} title="OK" />
            </View>
          )}
        </SafeAreaView>

        {(item.type == 'time' || item.type == 'datetime') && (
          <>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 20,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NumericInput
                onChange={(value) => {
                  setLocalHour(value);
                  let hour = zeroPad(value, 2);
                  let minute = zeroPad(localMinute, 2);
                  let time = hour + ':' + minute;
                  dispatch(
                    setAnswer({
                      linkId: item.linkId,
                      answer: calculateAnswer(null, time),
                    }),
                  );
                }}
                min={0}
                max={maxHour}
                value={localHour}
                strict={true}
              />
              <Text> {hourHint} </Text>
              {/*</View>*/}
              {/**/}
              {/*<View style={{*/}
              {/*    'flexDirection': 'row',*/}
              {/*    'marginTop': 20,*/}
              {/*    'justifyContent': 'center',*/}
              {/*    'alignItems': 'center'*/}
              {/*}}>*/}
              <NumericInput
                onChange={(value) => {
                  setLocalMinute(value);
                  let hour = zeroPad(localHour, 2);
                  let minute = zeroPad(value, 2);
                  let time = hour + ':' + minute;
                  dispatch(
                    setAnswer({
                      linkId: item.linkId,
                      answer: calculateAnswer(null, time),
                    }),
                  );
                }}
                min={0}
                max={59}
                value={localMinute}
                strict={true}
              />
              <Text> {minuteHint}</Text>
            </View>
          </>
        )}

        {showDatePicker && (
          <SafeAreaView>
            <View
              style={{
                justifyContent: 'center',
                flex: 1,
                alignItems: 'center',
              }}
            >
              <DatePickerModal
                locale="de"
                mode="single"
                visible={showDatePicker}
                onDismiss={onDismissSingle}
                date={localDate ? localDate : new Date(initValue)}
                onConfirm={onConfirmSingle}
                validRange={{ startDate: minDate, endDate: maxDate }}
                saveLabel="Speichern"
                label="Datum auswählen"
              />
            </View>
          </SafeAreaView>
        )}
        <Text
          style={{ color: theme.colors.no, fontWeight: 'bold', marginTop: 10 }}
        >
          {errorMsg}
        </Text>
      </View>
    </>
  );
}

DateInput.propTypes = {
    item: PropTypes.shape(itemPropType).isRequired,
};

function markdownToHtml(markdown: string): string {
    const breakRegex = /\r?\n/g;
    markdown = markdown.replace(breakRegex, '<br />');

    // Replace boldface syntax with <strong> tags
    const boldRegex = /\*{2}(.+?)\*{2}/g;
    markdown = markdown.replace(boldRegex, "<strong>$1</strong>");

    // Replace italic syntax with <em> tags
    const italicRegex = /\/\/(.+?)\/\//g;
    markdown = markdown.replace(italicRegex, "<em>$1</em>");

    // Replace underlined syntax with <u> tags
    const underlineRegex = /_{2}(.+?)_{2}/g;
    markdown = markdown.replace(underlineRegex, "<u>$1</u>");

    return markdown;
}

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const modalWidth = Dimensions.get('window').width - 40;

const localStyle = StyleSheet.create({
    dateTimePickerButtonBar: {
        flexWrap: 'nowrap',
        textAlign: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        backgroundColor: 'transparent',
        paddingRight: 20,
    },

    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: '100%'
    },


    maskedInput: {
        borderWidth: 1,
        borderRadius: 6,
        width: '80%',
        marginTop: 8,
        padding: 12,
        color: 'black',
        fontSize: 20
    },

    dateTimePickerButton: {
        paddingRight: 40,
    },

    iOSButton: {
        color: theme.colors.accent4,
        borderWidth: 1,
        borderRadius: 6,
        borderColor: theme.colors.accent4,
        padding: 15,
        fontSize: 22
    },

    iOSButtonOK: {
        color: theme.colors.white,
        backgroundColor: theme.colors.yes
    },
});
