// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React from 'react';
import {Image, StyleSheet, useWindowDimensions, View} from 'react-native';
import PropTypes from 'prop-types';
import {navigationPropType} from '../../propTypes';

// custom components

// services & config
import {theme} from '../../config';
import RenderHtml from "react-native-render-html";

/***********************************************************************************************
 * component:
 * renders the legal information screen which contains legal information about the study
 *
 * @param {object} props
 * @param {object} props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function AatmInfoScreen({navigation}) {

    let {width} = useWindowDimensions();


    return (<View style={localStyle.wrapper}>
        <View style={{ width: 700}}>
            {/* content */}
            <Image source={require('src/assets/images/illu-allgemeine-infos-aatm.png')}
                   style={{width: 1170 / 2.5, height: 855 / 2.5, align: 'center', alignSelf: 'center'}}
                   resizeMode={"contain"}/>
            <RenderHtml contentWidth={width} source={{
                html:
                    '<h1 >Ein Überblick über den Alpha-1-Antitrypsin-Mangel</h1><p style="font-size: 120%">Der Alpha-1-Antitrypsin-Mangel ' +
                    '(AATM) ist eine erbliche (genetische) Erkrankung. Die Wahrscheinlichkeit mit einem schweren AATM geboren zu werden, beträgt ' +
                    'in Europa bis zu 1:2000-4000. Obwohl der Alpha-1-Antitrypsin-Mangel heutzutage leicht diagnostiziert werden kann ' +
                    '(eine Blutprobe genügt), wird die Diagnose häufig zu spät oder gar nicht gestellt.</p><p style="font-size: 120%">Bei gesunden Menschen bildet die ' +
                    'Leber das Protein Alpha-1-Antitrypsin und gibt es ins Blut ab. Liegt ein Alpha-1-Antitrypsin-Gendefekt vor, bildet die ' +
                    'Leber ein fehlerhaftes Alpha-1-Antitrypsin.</p><h2>Das kann zu zwei Problemen führen:</h2> '

            }}/>

            <Image source={require('src/assets/images/aatm1-info-1.png')}
                   style={{width: 3200 / 4, height: 3300 / 4}} resizeMode={"contain"}/>


            <RenderHtml contentWidth={width} source={{
                html:

                    '<p style="font-size: 120%"><strong>In der Leber: </strong>Das fehlerhafte Protein verbleibt in der Leber und führt dort zu einer ' +
                    'Art Verstopfung (Akkumulation). Dadurch können Leberzellen geschädigt werden, was zu einer Einschränkung ' +
                    'der Leberfunktion führen kann. Dieses Problem kann bereits bei Neugeborenen, Säuglingen und Kleinkindern ' +
                    'oder auch erst später auftreten</p><p style="font-size: 120%"><strong>In der Lunge: </strong>Des Weiteren gelangt das Protein ' +
                    'durch die Akkumulation in der Leber nicht an sein eigentliches Ziel: die Lunge. Diese ist zahlreichen ' +
                    'äußeren Einflüssen ausgesetzt. Viren und Bakterien, aber zum Beispiel auch Tabakrauch und Feinstaub, ' +
                    'reizen sie. Hier kommen sogenannte Elastasen als Teil der normalen Abwehrreaktion ins Spiel. Diese ' +
                    'Elastasen reagieren unspezifisch, d.h. sie unterscheiden nicht zwischen körperfremd und körpereigen. ' +
                    'Bei gesunden Menschen reguliert das Alpha-1-Antitrypsin die Elastasen und kann deren überschießende ' +
                    'Reaktion verhindern. Bei Menschen mit einem Alpha-1-Antitrypsin-Mangel ist dieser Schutz nicht oder ' +
                    'nur unzureichend gegeben, was im Laufe des Lebens zu einer Schädigung der Lunge führt. Erste ' +
                    'lungenspezifische Symptome treten jedoch meist erst im mittleren Erwachsenenalter auf.</p> ' +
                    '<h2>Vererbung</h2><p style="font-size: 120%">Gene enthalten den Bauplan für ein Protein (Eiweißmolekül) und liegen im ' +
                    'menschlichen Erbgut in zwei Kopien (zwei Allele) vor – so auch das Gen für das Alpha-1-Antitrypsin. ' +
                    'Ein Allel stammt dabei vom Vater, das andere von der Mutter. Das normale Allel für Alpha-1-Antitrypsin ' +
                    'wird mit „M“ bezeichnet, die häufigste vorkommende Mutation mit „Z“. In Deutschland trägt etwa jeder ' +
                    'Fünfzigste eine solche Veränderung im AATM-Gen in sich und kann sein Z-Allel weitervererben. ' +
                    'Heterozygote Träger („MZ“, d.h. mit einem gesunden und einem veränderten Allel) haben meist keine ' +
                    'Beschwerden und wissen in der Regel nichts von ihrer Anlageträgerschaft. Sind beide Elternteile Träger ' +
                    'des Z-Allels, besteht die Möglichkeit, dass das Kind zwei Z-Allele vererbt bekommt. Dies führt zu einem ' +
                    'schweren, sogenannten homozygoten, Alpha-1-Antitrypsin-Mangel (ZZ).</p>'
            }}/>
            <Image source={require('src/assets/images/aatm1-info-2.png')}
                   style={{width: 3200 / 4, height: 1100 / 4}} resizeMode={"contain"}/>

        </View>
    </View>);
}

AatmInfoScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    wrapper: {
        height: '100%',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: theme.values.defaultBackgroundColor,
    }
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default AatmInfoScreen;
