// (C) Copyright IBM Deutschland GmbH 2021.  All rights reserved.

/***********************************************************************************************
 imports
 ***********************************************************************************************/

import React from 'react';
import {Image, StyleSheet, TouchableOpacity, useWindowDimensions, View} from 'react-native';
import PropTypes from 'prop-types';
import {navigationPropType} from '../../propTypes';

// custom components
// services & config
import {theme} from '../../config';
import RenderHtml from "react-native-render-html";

/***********************************************************************************************
 * component:
 * renders the legal information screen which contains legal information about the study
 *
 * @param {object} props
 * @param {object} props.navigation the navigation object provided by 'react-navigation'
 ***********************************************************************************************/
function LinksScreen({navigation}) {

    let {width} = useWindowDimensions();


    return (<View style={localStyle.wrapper}>
        <View style={{ width: 700}}>
            {/* content */}
            <Image source={require('src/assets/images/illu-allgemeine-infos-aatm.png')}
                   style={{width: 1170 / 2.5, height: 855 / 2.5, align: 'center', alignSelf: 'center'}}
                   resizeMode={"contain"}/>
            <RenderHtml contentWidth={width} source={{
                html:
                    '<h1 >Wichtige Links</h1>' +
                    '<p style="font-size: 120%">' +
                    '</p>'

            }}/>
            <TouchableOpacity onPress={() => {
                window.open(
                    'https://leberkrankes-kind.de/'
                )
            }}>
                <Image source={require('src/assets/images/links_leberkrankes_kind.png')}
                       style={{width: 672 / 2, height: 164 / 2}}
                       resizeMode={"contain"}/>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => {
                window.open(
                    'https://www.alpha1-deutschland.org/'
                )
            }}>
                <Image source={require('src/assets/images/links_alpha1_deutschland.png')}
                       style={{width: 1170 / 4, height: 855 / 4}}
                       resizeMode={"contain"}/>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => {
                window.open(
                    'https://www.alpha1bonn.de/'
                )
            }}>
                <Image source={require('src/assets/images/links_alpha1bonn.png')}
                       style={{width: 500 / 1.7, height: 95 / 1.7}}
                       resizeMode={"contain"}/>
            </TouchableOpacity>


        </View>
    </View>)

}

LinksScreen.propTypes = {
    navigation: PropTypes.shape(navigationPropType).isRequired,
};

/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const localStyle = StyleSheet.create({
    wrapper: {
        height: '100%',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: theme.values.defaultBackgroundColor,
    }
});

/***********************************************************************************************
 export
 ***********************************************************************************************/

export default LinksScreen;
