/**
 * utility functions used throughout the application
 */

import moment from 'moment';
import 'moment/locale/de';
import ReactNativeBiometrics, {BiometryTypes} from "react-native-biometrics";
import {Alert, Linking, Platform} from "react-native";
import {setUseAppLock} from "../store/user.slice";
import {setIsBiometryAuthenticated} from "../store/globals.slice";
import showMessage from "../components/shared/showMessage";
import VersionCheck from '../components/shared/VersionCheck';
import endpoints from '../services/rest/endpoints';
import translate from '../services/localization';
import i18n from 'i18n-js';
import { Routes, Stacks } from '../navigation/constants';
// import PushNotification from 'react-native-push-notification';
import PushNotification from '../components/shared/PushNotification'; 
// TODO: check if notifee is supported on web
import notifee, { AndroidNotificationSetting } from '@notifee/react-native';

String.prototype.hashCode = function () {
  let hash = 0,
    i,
    chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

/**
 * generates a date string from Date Object (dd.mm.yyyy)
 * @param  {Date}    inputDate the input date
 * @param  {boolean} includeTime if true: the exported string will also contain the time
 */
const formatDateString = (inputDate, { includeTime, locale = 'de' }) => {
  return moment(new Date(inputDate)).locale(locale).format(!includeTime ? 'LL' : 'LLL');
};

/**
 * converts a yyyy-mm-dd string to a Date object
 * @param  {Date}    inputDate the input date string in yyyy-mm-dd format
 */
const yyyymmddToDate = (dateString) => {
  const dateParts = dateString.split('-').map(Number);

  // Ensure there are three parts (year, month, day)
  if (dateParts.length !== 3) {
    return null;
  }

  const [year, month, day] = dateParts;

  // Check if the date is valid
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return null;
  }

  const dateObject = new Date(year, month - 1, day);

  // Check if the date is valid after considering month indexing (0-based in JavaScript)
  if (
      dateObject.getFullYear() !== year ||
      dateObject.getMonth() !== month - 1 ||
      dateObject.getDate() !== day
  ) {
    return null;
  }

  return dateObject;
}

const stripTags = (input: string) => {
  return input.replace(/(\[.*?\])/gm, '').trim();
}

const switchAppLock = (dispatch, appLockSetting) => {
  const rnBiometrics = new ReactNativeBiometrics({allowDeviceCredentials: true});

  rnBiometrics.isSensorAvailable()
      .then((resultObject) => {
        const {available, biometryType} = resultObject

        let promptMessage = '';
        if (available && biometryType === BiometryTypes.TouchID) {
          promptMessage = 'Mit Touch ID entsperren';
        } else if (available && biometryType === BiometryTypes.FaceID) {
          promptMessage = 'Mit FaceID entsperren';
        } else if (available && biometryType === BiometryTypes.Biometrics) {
          promptMessage = 'Mit Fingerabdruck entsperren';
        } else {
          console.log('Biometrics not supported')
        }

        let fallbackPromptMessage = 'Geben Sie Ihre PIN ein';
        if (Platform.OS === 'ios') {
          fallbackPromptMessage = 'Geben Sie Ihren Passcode ein';
        }

        if (available) {
          rnBiometrics.simplePrompt({
            promptMessage: promptMessage,
            fallbackPromptMessage: fallbackPromptMessage,
            cancelButtonText: 'Abbruch',
          })
              .then((resultObject) => {
                const {success} = resultObject

                if (success) {
                  dispatch(setUseAppLock(appLockSetting)).then(()=> {
                      let message = appLockSetting ?
                        'Die App-Sperre ist nun gesetzt.' : 'Die App-Sperre wurde entfernt.';
                      if (Platform.OS === 'native') {
                        showMessage({
                            message: message,
                            position: "bottom",
                            type: "info",
                        })
                      } else {
                        window.alert(message);
                      }
                  });
                  dispatch(setIsBiometryAuthenticated(true));
                  console.log('successful biometrics provided')
                } else {
                  console.log('user cancelled biometric prompt')
                }
              })
              .catch((e) => {
                console.log('biometrics failed')
                console.error(e)
              })
        }
      })

}

const versionCheck = (navigation, dispatch, currentRoute, subjectId) => {
  if (Platform.OS === 'native')
    {let country = '';
    VersionCheck.getCountry()
        .then(c => country = c);
    const body = {
        'country': country,
        'lang': i18n.locale,
        'package': VersionCheck.getPackageName(),
        'build': VersionCheck.getCurrentBuildNumber(),
        'version': VersionCheck.getCurrentVersion()
    };

    let endpoint = endpoints.checkVersion;

    VersionCheck.getLatestVersion({
        forceUpdate: true,
        provider: () => fetch(endpoint, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(r => r.json(), function (error) {
                console.log("ERROR: ", error.message)
            })
            .then(a => {
                console.log(a);
                return a
            }),
    }).then(updateResponse => {
        const infoURL = updateResponse?.infoURL;

        if (updateResponse.inMaintenanceMode && currentRoute?.name !== Routes.MAINTENANCE_MODE) { // check if should get into maintenance mode
            navigation.replace(Stacks.FORCE_NEW_VERSION, {screen: Routes.MAINTENANCE_MODE});
            return;
        } else if (!updateResponse.inMaintenanceMode && currentRoute?.name === Routes.MAINTENANCE_MODE) {
            navigation.replace(subjectId ? Stacks.SIGNED_IN : Stacks.SIGNED_OUT);
            return;
        }

        if (!infoURL) {
            return;
        }
        if (!infoURL || updateResponse.forceUpdate) {
            dispatch(setNewVersionURL(infoURL))
            navigation.replace(Stacks.FORCE_NEW_VERSION, {screen: Routes.LOAD_NEW_VERSION});
        } else if (updateResponse.updateAvailable) {
            let buttons = [];

            buttons.push(
                {
                    text: translate('generic').abort,
                    style: 'cancel',
                });

            console.log("infoURL", infoURL);
            buttons.push(
                {
                    text: translate('generic').ok,
                    onPress: () => {
                        console.log(updateResponse);
                        if (infoURL) {
                            Linking.openURL(infoURL);
                        }
                        if (updateResponse.forceUpdate) {
                            navigation.replace(Stacks.FORCE_NEW_VERSION, Routes.LOAD_NEW_VERSION);
                        }
                    },
                }
            );

            Alert.alert(
                translate('generic').info,
                updateResponse.infoText ?? "Eine neue Version der App ist verfügbar.",
                buttons,
                {cancelable: false},
            );
        }
    }).catch((e) => {
        console.error('Error while checking version')
        console.error(e)
    });}
}

let scheduleNotification = (notificationId, title, body, scheduledDate) => {
    PushNotification.cancelLocalNotification({id: notificationId});
    const notificationOptions = {
        channelId: "Alpha1KIDS",
        id: notificationId.hashCode(),
        title: "Alpha-1-KIDS: " + title,
        message: body,
        date: scheduledDate,
        playSound: true,
        allowWhileIdle: true,

        largeIcon: 'ic_launcher',
        smallIcon: 'push_notification_icon',
        bigLargeIcon: 'ic_launcher'
    };
    console.log('will notify', JSON.stringify(notificationOptions));
    notifee.getNotificationSettings().then((settings) => {
        console.log('got settings');
        if (settings.android.alarm === AndroidNotificationSetting.ENABLED) {
            console.log('will notify alarm is ok');
            PushNotification.localNotificationSchedule(notificationOptions);
        }
    });
}
let cancelNotification = (notificationId) => {
    PushNotification.cancelLocalNotification(notificationId.hashCode())
}

// eslint-disable-next-line import/prefer-default-export
export {formatDateString};
export {stripTags};
export {yyyymmddToDate};
export {switchAppLock};
export {versionCheck};
export {scheduleNotification};
export {cancelNotification};
